
import { List, ListItem, ListItemIcon, ListItemText, makeStyles, Divider, Switch, FormControlLabel } from '@material-ui/core'
import React from 'react'
import { Merchant, MerchantBasic } from './models/RealmDataModels';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import { useAuth } from './providers/AuthProvider';
import { BSON } from 'realm-web';
import { Link } from "react-router-dom";


interface MerchantsListProps {
  onUpdated: () => void;
  merchants: MerchantBasic[];
  selectedMerchantSlug?: string;
}

export const MerchantsList = (props: MerchantsListProps) => {
  const classes = useStyles();
  const { merchants, selectedMerchantSlug, onUpdated } = props;
  const auth = useAuth();

  const _handleActiveChange = async (merchantId: BSON.ObjectId, active: boolean) => {
    const user = auth.getUser();
    if (user) {
      const mongodb = user.mongoClient("mongodb-atlas");
      const merchants = mongodb.db("jymba").collection<Merchant>("Merchant");
      await merchants.updateOne({ _id: merchantId }, {
        $set: { _status: active ? 'active' : 'inactive' }
      });
      onUpdated();
    }
  }

  return (
    <List dense>
      <ListItem
        button
        selected={selectedMerchantSlug === undefined}
        component={Link}
        to="/merchant">
        <ListItemIcon>
          <AddCircleOutlineOutlinedIcon color="primary" />
        </ListItemIcon>
        <ListItemText color="primary" primary="Create New Merchant" />
      </ListItem>
      <Divider />

      {merchants.map((merch, idx) => (
        <React.Fragment key={merch.merchantCode}>
          <ListItem
            key={merch.merchantCode}
            button
            selected={selectedMerchantSlug === merch.slug}
            component={Link}
            to={`/merchant/${merch.slug}`}
          >
            <ListItemText primary={merch.name} secondary={merch.merchantCode} />
            <Switch
              size="small"
              checked={merch._status === 'active'}
              onChange={(e, checked) => _handleActiveChange(merch._id, checked)}
              color="secondary"
              name="active"
            />
            {/* <ListItemIcon onClick={() => _handleDownload(merch)}>
            </ListItemIcon> */}
          </ListItem>
          <Divider />
        </React.Fragment>
      ))}
    </List>
  )
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
}));