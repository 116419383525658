export const getTimeStringForMinutes = (mins: number) => {
  const hours = Math.floor(mins / 60);
  const minutes = mins % 60;
  let leadingMinZero = '';
  if (minutes < 10) {
    leadingMinZero = '0';
  }
  let leadingHourZero = '';
  if (hours < 10) {
    leadingHourZero = '0';
  }

  return `${leadingHourZero}${hours}:${leadingMinZero}${minutes}`;
};