import React, { useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useTheme, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { MerchantFormInput } from './CreateMerchantForm';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { useAuth } from './providers/AuthProvider';
import { Merchant, MerchantSpectialTiming, MerchantWeekdayTiming, TimeRange } from './models/RealmDataModels';
import { ImageUpload } from './ImageUpload';
import Resizer from 'react-image-file-resizer';
import { DBAutoComplete } from './DBAutoComplete';
import { Box, Button, Divider, IconButton, Slider } from '@material-ui/core';
import { getTimeStringForMinutes } from './utils/TimeUtils';
import { KeyboardDatePicker } from '@material-ui/pickers';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    input: {
      display: 'none',
    },
    timeInput: {
      marginLeft: 20,
      marginRight: 20,
    },
    image: {
      position: "relative",
      width: 400,
      height: 267,
      boxShadow: "rgb(171 153 145 / 24%) 0px 0px 2px 0px, rgb(171 158 145 / 24%) 0px 4px 8px -4px",
    },
    avatar: {
      position: "absolute",

      top: "30%",
      right: -theme.spacing(6),
    },
  }),
);
const filter = createFilterOptions<string>()

function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const services = [
  'Delivery',
  'Pickup',
  'Dining',
  'Wifi',
];
export interface MerchantFormProps {
  merchant: MerchantFormInput;
  handleChange: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
}
interface BasicMerchantFormProps extends MerchantFormProps {
  handleCuisinesChange: (c: string[]) => void;
  handleCuisinesEnChange: (c: string[]) => void;
  handleImagesChange: (imageFiles: string[]) => void;
  handleLogoChange: (imageFile: string) => void;
  handleTimingsChange: (timings: Array<MerchantWeekdayTiming>) => void;
  handleSpecialTimingsChange: (specialTimings: Array<MerchantSpectialTiming>) => void;
}
type weekday = "mon" | "tue" | "wed" | "thu" | "fri" | "sat" | "sun";


const BasicsForm: React.FC<BasicMerchantFormProps> = (props) => {
  const { merchant } = props;
  const theme = useTheme();
  const classes = useStyles();
  const auth = useAuth();

  const getMarks = useCallback(() => {

    const marks = [];
    for (let index = 0; index <= 1440; index += 30) {
      if (index % 120 === 0) {
        marks.push({
          value: index,
          label: getTimeStringForMinutes(index),
        })
      } else {
        marks.push({
          value: index,
          label: '',
        })
      }
    }
    return marks;
  }, []);


  const [personName] = React.useState<string[]>([]);
  const [swtichState, setSwitchState] = React.useState({
    mon: true,
    tue: true,
    wed: true,
    thu: true,
    fri: true,
    sat: true,
    sun: true
  });

  const loadCusines = async () => {
    if (!auth.user) return [];

    const mongodb = auth.user.mongoClient("mongodb-atlas");
    const merchants = mongodb.db("jymba").collection<Merchant>("Merchant");
    const dbMerchants = await merchants.find();
    const allCusines = dbMerchants.map((merch) => merch.cuisines);
    const uniqCusines = [...new Set(allCusines.flat(1))];

    return uniqCusines;
  }

  const loadCusinesEn = async () => {
    if (!auth.user) return [];

    const mongodb = auth.user.mongoClient("mongodb-atlas");
    const merchants = mongodb.db("jymba").collection<Merchant>("Merchant");
    const dbMerchants = await merchants.find();
    const allCusines = dbMerchants.map((merch) => merch.cuisinesEn);
    const uniqCusines = [...new Set(allCusines.flat(1))];

    return uniqCusines;
  }

  const handleSpecialTimeSliderChanged = (index: number, values: number[]) => {
    const currentSpecialTimings = merchant.specialTimings ? [...merchant.specialTimings] : [];

    const timeRanges: Array<TimeRange> = [];
    for (let index = 0; index < values.length - 1; index += 2) {
      const from = values[index];
      const to = values[index + 1];
      timeRanges.push({
        from,
        to
      })
    }

    if (index > -1) {
      currentSpecialTimings[index] = {
        specialDate: currentSpecialTimings[index].specialDate,
        openingHours: timeRanges
      }
    }
    props.handleSpecialTimingsChange(currentSpecialTimings);

  }

  const handleTimeSliderChanged = (weekdayKey: string, values: number[]) => {

    const currentTimings = [...merchant.timings];
    const index = currentTimings.findIndex(t => t.weekday === weekdayKey);

    // if no values are left we can disable the slider
    if (values.length === 0) {
      if (index > -1) {
        currentTimings.splice(index, 1);
      }
      props.handleTimingsChange(currentTimings);
      return;
    }

    const timeRanges: Array<TimeRange> = [];
    for (let idx = 0; idx < values.length - 1; idx += 2) {
      const from = values[idx];
      const to = values[idx + 1];
      timeRanges.push({
        from,
        to
      })
    }

    if (index > -1) {
      currentTimings[index] = {
        weekday: weekdayKey,
        openingHours: timeRanges
      }
    }
    props.handleTimingsChange(currentTimings);
  }

  const renderTimeInput = (weekdayKey: weekday | 'special', day: string, openingHours: Array<TimeRange>, handleChange: (key: string, values: number[]) => void) => {
    let values = [0, 1440];
    if (openingHours.length > 0) {
      values = openingHours.map((oH) => [oH.from, oH.to]).reduce((prev, curr) => [...prev, ...curr], []);
    }

    return (
      <Box my={2} width="100%">
        <Typography id="range-slider" gutterBottom>
          {day}
        </Typography>
        <Box display="flex" flexDirection="row">
          <Slider
            disabled={openingHours.length === 0}
            style={{ width: '100%' }}
            value={values}
            step={30}
            marks={getMarks()}
            min={0}
            max={1440}
            onChange={(e, value) => handleChange(weekdayKey, value as number[])}
            valueLabelFormat={(num, idx) => getTimeStringForMinutes(num)}
            aria-labelledby="range-slider"
            valueLabelDisplay="on"
            getAriaValueText={(num, idx) => getTimeStringForMinutes(num)}
          />
          <Button onClick={() => {
            const newVals = [...values];
            newVals.push(0, 1440);
            handleChange(weekdayKey, newVals);
          }}>
            Add
          </Button>
          <Button
            onClick={() => {
              const newVals = [...values];
              newVals.pop();
              newVals.pop();
              handleChange(weekdayKey, newVals);
            }}>
            Remove
          </Button>
        </Box>
      </Box>
    );
  }

  const resizeFile = (file: any) => new Promise(resolve => {
    Resizer.imageFileResizer(file, 256, 256, 'PNG', 100, 0,
      uri => {
        resolve(uri);
      }, 'base64');
  });

  const handleDeleteImageClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, idx: number) => {
    event.stopPropagation();
    event.preventDefault();
    const imgs = merchant.images;
    imgs.splice(idx, 1);
    props.handleImagesChange(imgs);
  }

  const handleUploadClick = async (event: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    if (event.target.files) {
      var file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      const resizedImg = await resizeFile(file);

      const imgs = merchant.images;
      imgs[idx] = resizedImg as string;
      props.handleImagesChange(imgs);
    }
  };


  const handleDeleteLogoClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, idx: number) => {
    event.stopPropagation();
    event.preventDefault();

    props.handleLogoChange("");
  }

  const handleUploadLogo = async (event: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    if (event.target.files) {
      var file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      const resizedImg = await resizeFile(file);

      props.handleLogoChange(resizedImg as string);
    }
  };

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Restaurant Basics
      </Typography>
      <Typography variant="body2" gutterBottom color="textSecondary">
        Current Slug: {merchant?.slug}
      </Typography>

      <Box my={5} />

      <Grid container spacing={3}>

        <Grid container spacing={2} item xs={12} sm={6}>

          <Grid item xs={12}>
            <TextField
              required
              id="name"
              name="name"
              label="Restaurant Name"
              fullWidth
              focused
              value={merchant?.name}
              onChange={props.handleChange}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              id="website"
              name="website"
              label="Website"
              focused={merchant?.website !== ''}
              value={merchant?.website}
              fullWidth
              onChange={props.handleChange}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="phone"
              name="phone"
              label="Phone"
              focused={merchant?.phone !== ''}
              value={merchant?.phone}
              fullWidth
              onChange={props.handleChange}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              id="paypalId"
              name="paypalId"
              label="paypalId"
              focused={merchant?.paypalId !== ''}
              value={merchant?.paypalId}
              fullWidth
              onChange={props.handleChange}
            />
          </Grid>

        </Grid>

        <Grid item xs={12} sm={6}>
          <Grid container alignItems="center" justifyContent='center' >
            <Typography variant="h6" gutterBottom style={{ textAlign: 'center' }}>
              Restaurant Profile Image
            </Typography>
            <Grid item xs={12}>

              <Box display="flex" justifyContent="center">
                <Box className={classes.image} style={{ boxShadow: '0px 0px 7px 5px #b3b1b1' }}>
                  
                  <ImageUpload
                    id="merchant-image"
                    idx={0}
                    img={merchant.images[0]}
                    handleUploadClick={handleUploadClick}
                    handleDeleteImageClick={handleDeleteImageClick}
                  />
                  <ImageUpload
                    round
                    id="merchant-logo"
                    idx={0}
                    img={merchant.logoUrl}
                    handleUploadClick={handleUploadLogo}
                    handleDeleteImageClick={handleDeleteLogoClick}
                    className={classes.avatar}
                  />
                </Box>
              </Box>

            </Grid>
          </Grid>

        </Grid>

        <Divider />

        <Grid item xs={12}>
          <TextField
            required
            id="desc"
            name="desc"
            label="Description"
            fullWidth
            multiline
            rows={2}
            focused={merchant?.desc !== ''}
            value={merchant?.desc}
            onChange={props.handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="imprint"
            name="imprint"
            label="Imprint"
            focused={merchant?.imprint !== ''}
            value={merchant?.imprint}
            fullWidth
            multiline
            rows={2}
            onChange={props.handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <p>Services</p>
          <Select
            labelId="demo-mutiple-name-label"
            id="demo-mutiple-name"
            name="services"
            multiple
            fullWidth
            value={merchant?.services}
            onChange={props.handleChange}
            input={<Input />}
            MenuProps={MenuProps}
          >
            {services.map((name) => (
              <MenuItem key={name} value={name} style={getStyles(name, personName, theme)}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        {/* Cuisines */}
        <Grid item xs={12} sm={12}>
          <p>Cuisines Deutsch</p>
          <DBAutoComplete
            multiple
            id="cuisines"
            label="Cuisines Deutsch"
            onChange={(newVal) => props.handleCuisinesChange(newVal as string[])}
            value={merchant.cuisines}
            optionLoadFunction={loadCusines}
          />
        </Grid>
        {/* CuisinesEn */}
        <Grid item xs={12} sm={12}>
          <p>Cuisines English</p>
          <DBAutoComplete
            multiple
            id="cuisines"
            label="Cuisines English"
            onChange={(newVal) => props.handleCuisinesEnChange(newVal as string[])}
            value={merchant.cuisinesEn}
            optionLoadFunction={loadCusinesEn}
          />
        </Grid>
        <Grid item xs={12} sm={12} >
          <Box>
            <Typography variant="h6" gutterBottom>
              Opening Times
            </Typography>
            <Box p={2}>
              {renderTimeInput('mon', 'Monday', merchant.timings.find((timing) => timing.weekday === 'mon')?.openingHours || [], handleTimeSliderChanged)}
              {renderTimeInput('tue', 'Tuesday', merchant.timings.find((timing) => timing.weekday === 'tue')?.openingHours || [], handleTimeSliderChanged)}
              {renderTimeInput('wed', 'Wednesday', merchant.timings.find((timing) => timing.weekday === 'wed')?.openingHours || [], handleTimeSliderChanged)}
              {renderTimeInput('thu', 'Thursday', merchant.timings.find((timing) => timing.weekday === 'thu')?.openingHours || [], handleTimeSliderChanged)}
              {renderTimeInput('fri', 'Friday', merchant.timings.find((timing) => timing.weekday === 'fri')?.openingHours || [], handleTimeSliderChanged)}
              {renderTimeInput('sat', 'Saturday', merchant.timings.find((timing) => timing.weekday === 'sat')?.openingHours || [], handleTimeSliderChanged)}
              {renderTimeInput('sun', 'Sunday', merchant.timings.find((timing) => timing.weekday === 'sun')?.openingHours || [], handleTimeSliderChanged)}
            </Box>
          </Box>
        </Grid>

        <Divider />

        <Grid item xs={12} sm={12} >
          <Box>
            <Typography variant="h6" gutterBottom>
              Spectial Times
            </Typography>
            <Box p={2}>
              {merchant && merchant.specialTimings && (
                merchant.specialTimings.map((specialTiming, idx) => {
                  return (
                    <>
                      <Grid container spacing={5} key={specialTiming.specialDate.toDateString()} alignItems="center">
                        <Grid item xs={1}>
                          <IconButton
                            onClick={() => {
                              var specTimes = merchant.specialTimings ? [...merchant.specialTimings] : [];
                              specTimes.splice(idx, 1);
                              props.handleSpecialTimingsChange(specTimes)
                            }}>
                            <DeleteForeverIcon />
                          </IconButton>
                        </Grid>
                        <Grid item xs={2}>
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            label="Special Date"
                            value={specialTiming.specialDate}
                            onChange={(date) => {
                              var specTimes = merchant.specialTimings ? [...merchant.specialTimings] : [];
                              if (date) {
                                specTimes[idx].specialDate = new Date(date);
                                props.handleSpecialTimingsChange(specTimes)
                              }
                            }}
                            KeyboardButtonProps={{
                              'aria-label': 'change date'
                            }}
                          />
                        </Grid>
                        <Grid item xs={9}>
                          {renderTimeInput('special', ``, specialTiming.openingHours, (key, values) => handleSpecialTimeSliderChanged(idx, values))}
                        </Grid>

                      </Grid>
                      <Divider />
                    </>
                  );
                })
              )}

            </Box>
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => {
                var specTimes = merchant.specialTimings ? [...merchant.specialTimings] : [];
                specTimes.push({
                  specialDate: new Date(),
                  openingHours: [{ from: 0, to: 1440 }]
                });
                props.handleSpecialTimingsChange(specTimes)
              }}>
              Add Special Timing
            </Button>
          </Box>
        </Grid>


      </Grid>
    </React.Fragment>
  );
}

export default BasicsForm;
