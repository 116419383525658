import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import * as Realm from "realm-web";
import { getOtherRealmApp, getRealmApp } from '../AppConfig';
import firebase from "firebase";


const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

interface AuthContextType {
  isAuthenticated: boolean;
  login: (
    email: string,
    password: string,
  ) => Promise<boolean>;
  user: Realm.User | undefined;
  getUser: () => Realm.User | undefined;
  getOtherUser: () => Realm.User | undefined;
  getFirebaseStorage: () => Promise<firebase.storage.Storage | undefined>;
}
// Create an auth Context
const AuthContext = createContext<AuthContextType>({} as AuthContextType);

interface AuthProviderProps {
  children?: ReactNode;
}

// Access the Realm App.
const app = getRealmApp();
const otherApp = getOtherRealmApp();

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {

  const getUser = () => {
    if (app.currentUser) {
      app.currentUser.refreshAccessToken();
      setIsAuthenticated(true);
      setUser(app.currentUser);
    }
    return app.currentUser || undefined;
  }

  const getOtherUser = () => {
    if (otherApp.currentUser) {
      otherApp.currentUser.refreshAccessToken();
      setIsAuthenticated(true);
      setOtherUser(otherApp.currentUser);
    }
    return otherApp.currentUser || undefined;
  }

  const getFirebaseStorage = async () => {
    if(storage){
      return storage;
    }
    return await firebaseLogin();
  }


  useEffect(() => {
    if (app.currentUser) {
      setIsAuthenticated(true);
      setUser(app.currentUser);
    }
  }, [])

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<Realm.User>();
  const [otherUser, setOtherUser] = useState<Realm.User>();
  const [storage, setStorage] = useState<firebase.storage.Storage>();

  const firebaseLogin = async () => {
    var app = firebase.initializeApp(firebaseConfig);
    try {
      const email = process.env.REACT_APP_FIREBASE_ADMIN_EMAIL || '';
      const pwd = process.env.REACT_APP_FIREBASE_ADMIN_PASSWORD || '';
      await app.auth().signInWithEmailAndPassword(email, pwd);
      const storage = firebase.storage(app);
      setStorage(storage);
    } catch (error) {
      console.log("Could not log in into firebase: ", error.message);
    }
    return storage;
  }

  const login = async (email: string, password: string) => {
    // Create an anonymous credential
    const credentials = Realm.Credentials.emailPassword(email.toLowerCase(), password);

    // Authenticate the user
    const user: Realm.User = await app.logIn(credentials);
    const otherUser: Realm.User = await otherApp.logIn(credentials);
    setIsAuthenticated(true);
    setUser(user);
    setOtherUser(otherUser);

    // Log into Firebase
    await firebaseLogin();
    return true;
  }

  return (
    <AuthContext.Provider
      value={{ getUser, isAuthenticated, login, user, getOtherUser, getFirebaseStorage }}>
      {children}
    </AuthContext.Provider>
  );
};

// The useAuth hook can be used by components under an AuthProvider to access the auth context value.
export const useAuth = () => {
  const authContext = useContext(AuthContext);
  if (authContext == null) {
    throw new Error('useAuth() called outside of a AuthProvider?');
  }
  return authContext;
};
