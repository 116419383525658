import React from 'react';
import { Route, BrowserRouter as Router, Redirect, Switch } from "react-router-dom";
import AdminPanelPage from '../Pages/AdminPanelPage';
import ChoosePage from '../Pages/ChoosePage';
import CouponsPage from '../Pages/CouponsPage';
import LogInPage from '../Pages/LogInPage';
import ResetPasswordPage from '../Pages/ResetPasswordPage';
import ReviewPage from '../Pages/ReviewPage';
import PrivateRoute from './PrivateRoute';

export interface RoutesProps {}

const Routes: React.FC<RoutesProps> = () => {
  return (
    <Router>
      <Switch>
        <PrivateRoute exact path="/choose">
          <ChoosePage />
        </PrivateRoute>

        <PrivateRoute path="/merchant/:id">
          <AdminPanelPage />
        </PrivateRoute>

        <PrivateRoute exact path="/merchant">
          <AdminPanelPage />
        </PrivateRoute>

        <PrivateRoute path="/coupons/:id">
          <CouponsPage />
        </PrivateRoute>

        <PrivateRoute exact path="/coupons">
          <CouponsPage />
        </PrivateRoute>

        <PrivateRoute exact path="/reviews">
          <ReviewPage />
        </PrivateRoute>

        <PrivateRoute exact path="/">
          <Redirect to="/choose" />
        </PrivateRoute>

        <Route exact path="/login">
          <LogInPage />
        </Route>

      </Switch>
    </Router>
  )
};

export default Routes;
