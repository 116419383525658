import * as Realm from "realm-web";

let app: Realm.App;
let otherApp: Realm.App;
// Returns the shared instance of the Realm app.
export const getRealmApp = () => {
  if (app === undefined) {
    const appId = process.env.REACT_APP_API_KEY || ''; // Set Realm app ID here.
    const appConfig = {
      id: appId,
      timeout: 10000,
      app: {
        name: 'default',
        version: '0',
      },
    };
    app = new Realm.App(appConfig);
  }
  return app;
};

export const getOtherRealmApp = () => {
  if (otherApp === undefined) {
    const appId = process.env.REACT_APP_API_KEY_OTHER || ''; // Set Realm app ID here.
    const appConfig = {
      id: appId,
      timeout: 10000,
      app: {
        name: 'default',
        version: '0',
      },
    };
    otherApp = new Realm.App(appConfig);
  }
  return otherApp;
};