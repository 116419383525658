import {
  AppBar,
  Backdrop,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Container,
  CssBaseline,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import React, { useEffect } from "react";
import { useAuth } from "../providers/AuthProvider";
import { makeStyles } from "@material-ui/core/styles";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import HomeIcon from "@material-ui/icons/Home";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckIcon from "@material-ui/icons/Check";
import { Merchant, Review } from "../models/RealmDataModels";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    borderBottomWidth: 2,
    borderBottom: "10px solid " + theme.palette.primary.main,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  appBarShift: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    gridGap: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
  },
  button: {
    alignSelf: "center",
  },
  drawerContainer: {
    overflow: "auto",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const ReviewPage = () => {
  const auth = useAuth();
  const classes = useStyles();
  const history = useHistory<{ from?: string }>();

  const [reviews, setReviews] = React.useState<
    Array<Review & { merchant: Array<Merchant> }>
  >([]);

  useEffect(() => {
    if (auth.getUser()) {
      loadReviews();
    }
  }, [auth.getUser()]);

  const user = auth.getUser();
  if (!user) {
    console.log("No user");
    return null;
  }

  const mongodb = user.mongoClient("mongodb-atlas");
  const reviewColl = mongodb
    .db("jymba")
    .collection<Review & { merchant: Array<Merchant> }>("Review");

  const loadReviews = async () => {
    const dbReview = await reviewColl.aggregate([
      {
        $match: {
          approved: false,
        },
      },
      {
        $lookup: {
          from: "Merchant",
          localField: "merchantId",
          foreignField: "_id",
          as: "merchant",
        },
      },
    ]);
    setReviews(dbReview);
  };

  const handleApproveClick = async (
    review: Review & { merchant: Array<Merchant> }
  ) => {
    await reviewColl.updateOne(
      { _id: review._id },
      { $set: { approved: true } }
    );
    loadReviews();
  };

  const handleDeleteClick = async (
    review: Review & { merchant: Array<Merchant> }
  ) => {
    await reviewColl.updateOne(
      { _id: review._id },
      { $set: { approved: false, _status: "deleted" } }
    );
    loadReviews();
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar color="default" position="fixed" className={clsx(classes.appBar)}>
        <Toolbar>
          <Typography variant="h5" color="textPrimary" noWrap>
            Jymba
            <Typography variant="body1" color="secondary" noWrap>
              {process.env.REACT_APP_ENVIRONMENT}
            </Typography>
          </Typography>
          <Box ml="auto" />
          <IconButton
            color="inherit"
            edge="start"
            onClick={() => {
              history.push("/choose");
            }}
          >
            <HomeIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <main className={clsx(classes.content)}>
        <div className={classes.drawerHeader} />
        <Container maxWidth="lg">
          <Grid container spacing={1}>
            {reviews.map((review) => (
              <Grid item xs={4}>
                <Card style={{ margin: 5, minHeight: 250}} variant="outlined">
                  <CardContent>
                    <Typography gutterBottom variant="h6">
                      {review.merchant.length > 0
                        ? review.merchant[0].name
                        : ""}
                    </Typography>
                    <Typography variant="body2">{review.reviewAs}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {review.comment}
                    </Typography>
                    <Rating value={review.rating} readOnly />
                    <Typography gutterBottom variant="body2">
                      {new Date(review._createdOn ?? "").toDateString()}
                    </Typography>
                    {/* <Typography variant="body2" color="textSecondary">
                      {review.comment}
                    </Typography> */}
                  </CardContent>

                  <CardActions>
                    <Button
                      variant="outlined"
                      color="secondary"
                      startIcon={<CheckIcon />}
                      onClick={() => handleApproveClick(review)}
                    >
                      Approve
                    </Button>
                    <Button
                      variant="outlined"
                      color="default"
                      startIcon={<DeleteIcon />}
                      onClick={() => handleDeleteClick(review)}
                    >
                      Delete
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
    </div>
  );
};
export default ReviewPage;
