import React, { useRef, useState } from 'react'
import { makeStyles, Theme, createStyles, Grid, AppBar, Toolbar, IconButton, Typography, Button, Paper, Backdrop, CircularProgress, List, ListItem, ListItemText, Container, Divider } from '@material-ui/core';
import { MenuItem, MenuItemCategory, Merchant } from '../models/RealmDataModels'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import { CategoryForm } from './CategoryForm';
import { MenuItemListItem } from '../MenuItemListItem';
import { BSON } from 'realm-web';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    paper: {
      padding: 10,
      height: '100%',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    }
  }),
);

export interface HandleSave {
  handleSave: () => void;
}

interface CategoryModalProps {
  category?: MenuItemCategory;
  handleClose: () => void;
  handleSave: (success: boolean) => void;
  handleAddMenuItem: (item?: MenuItem) => void;
  nextRank: number;
  merchant: Merchant;
}

export const CategoryModal = (props: CategoryModalProps) => {
  const classes = useStyles();
  const childRef = useRef<HandleSave>(null);
  const [loading, setLoading] = useState(false);

  const { category, handleClose, nextRank, merchant, handleSave, handleAddMenuItem } = props;

  return (
    <>
      <Grid container spacing={1}>

        {/* LEFT */}
        <Grid item xs={12} sm={9}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Add/Edit Category
              </Typography>
              <Button color="inherit" onClick={() => { childRef.current?.handleSave() }}>
                save
              </Button>
            </Toolbar>
          </AppBar>

          <Paper variant="outlined" square className={classes.paper}>
            <CategoryForm
              ref={childRef}
              nextRank={nextRank}
              category={category}
              merchant={merchant}
              handleSave={(success) => handleSave(success)}
            />
          </Paper>
        </Grid>

        {/* Right */}
        <Grid item xs={12} sm={3}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Typography variant="h6" className={classes.title}>
                Menu Items
              </Typography>
              <Button autoFocus color="inherit" startIcon={<AddCircleOutlineIcon />} onClick={() => handleAddMenuItem()}>
                Add Item
              </Button>
            </Toolbar>
          </AppBar>
          <Paper variant="outlined" square className={classes.paper}>
            {category && (
              <List dense>
                {category.items.map((item) => (
                  <>
                    <MenuItemListItem item={item} onClick={() => handleAddMenuItem(item)} />
                    <Divider />
                  </>
                ))}
              </List>
            )}
          </Paper>
        </Grid>

      </Grid>

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}
