import './App.css';
import { AuthProvider } from './providers/AuthProvider';
import Routes from './Routing/Routes';
import { MuiThemeProvider } from "@material-ui/core/styles";
import { createTheme } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

function App() {

  const theme = createTheme({
    palette: {
      primary: {
        main: '#fe4e01'
      },
      secondary: {
        main: '#fea701'
      },
      error: {
        main: '#ff0000'
      },
      success: {
        main: '#50cf50'
      }
    }
  });

  return (
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <AuthProvider>
          <Routes />
        </AuthProvider>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
}

export default App;
