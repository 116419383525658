import { List, ListSubheader, ListItem, ListItemIcon, ListItemText, ListItemAvatar, Avatar, ListItemSecondaryAction } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import {
  DragDropContext,
  Droppable,
  DropResult,
  Draggable,
  OnDragEndResponder
} from 'react-beautiful-dnd';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import DragHandleIcon from "@material-ui/icons/DragHandle";
import DeleteIcon from '@material-ui/icons/Delete';
import { MenuItemCategory } from '../models/RealmDataModels';
import { useAuth } from '../providers/AuthProvider';
import { reorder } from '../utils/reorder';
import makeStyles from '@material-ui/core/styles/makeStyles';


interface CategoryOrderListProps {
  categories: Array<MenuItemCategory>;
  classes: string;
  onUpdatedRanks: () => void;
  onLoading: (isLoading: boolean) => void;
}

const useStyles = makeStyles({
  draggingListItem: {
    background: 'rgb(235,235,235)'
  }
});


export const CategoryOrderList = (props: CategoryOrderListProps) => {
  const { categories, classes, onLoading, onUpdatedRanks } = props;
  const auth = useAuth();

  // const [reorderedCategories, setReorderedCategories] = useState<Array<MenuItemCategory>>([]);

  const updateCategoryRanksInDB = async (newArr: Array<MenuItemCategory>) => {
    if (!auth.user) return;

    const mongodb = auth.user.mongoClient("mongodb-atlas");
    const categoryCollection = mongodb.db("jymba").collection<MenuItemCategory>("MenuItemCategory");

    await Promise.all(
      newArr.map(async (category, idx) => {
        await categoryCollection.updateOne({ _id: category._id },
          { $set: { rank: idx + 1, _updatedOn: new Date(), _updatedBy: '1' } }
        )
      })
    );
  }

  const onDragEnd = async ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return;
    onLoading(true);

    const newItems = reorder(categories, source.index-1, destination.index -1);

    await updateCategoryRanksInDB(newItems as Array<MenuItemCategory>);
    onUpdatedRanks();

    onLoading(false);
  };

  return (
    <List dense className={classes}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-list">
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {categories.map((category) => (
                <CategoryListItem category={category} key={category.path} />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

    </List>
  )
}

interface CategoryListItemProps {
  category: MenuItemCategory;
}

const CategoryListItem = (props: CategoryListItemProps) => {
  const classes = useStyles();

  const { category } = props;
  return (

    <Draggable draggableId={category.path} index={category.rank}>
      {(provided, snapshot) => (
        <ListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={snapshot.isDragging ? classes.draggingListItem : ''}
        >
          <ListItemText
            primary={`${category.title['en']} - ${category.title['de']}`}
            secondary={`${category.desc['en']} - ${category.desc['de']}`}
          />

          <ListItemSecondaryAction>
            <ListItemIcon className="drag-handle">
              <DragHandleIcon />
            </ListItemIcon>
          </ListItemSecondaryAction>
        </ListItem>
      )}
    </Draggable>

  )
}
