import { TextField } from "@material-ui/core";
import { Label } from "@material-ui/icons";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import React, { useEffect, useState } from "react";

const filter = createFilterOptions<string>()


export interface DBAutoCompleteProps {
  onChange: (value: string | string[]) => void;
  value: string | string[];
  optionLoadFunction: () =>  Promise<Array<string>>;
  id: string;
  label: string;
  multiple?: boolean;
}

export const DBAutoComplete: React.FC<DBAutoCompleteProps> = (props) => {
  const [allOptions, setAllOptions] = useState<Array<string>>([]);
  const [openOptions, setOpenOptions] = React.useState(false);
  const loadingOptions = openOptions && allOptions.length === 0;

  useEffect(() => {
    let active = true;
    if (!loadingOptions) {
      return undefined;
    }
    const loadCategories = async () => {
      if (active) {
        setAllOptions(await props.optionLoadFunction());
      }
    }
    loadCategories();

    return () => {
      active = false;
    };
  }, [loadingOptions]);
  
  return (
    <Autocomplete
      open={openOptions}
      onOpen={() => {
        setOpenOptions(true);
      }}
      onClose={() => {
        setOpenOptions(false);
      }}
      value={props.value}
      size="small"
      fullWidth
      onChange={(event, newValue) => {
        if (newValue) {
          props.onChange(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (params.inputValue !== '') {
          filtered.push(params.inputValue);
        }
        return filtered;
      }}
      multiple={props.multiple}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id={props.id}
      options={allOptions}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        return option;
      }}
      renderOption={(option) => option}
      freeSolo
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label={props.label} placeholder={props.label + '...'} />
      )}
    />
  );
}