import { ListItem, ListItemAvatar, Avatar, ListItemText, Typography, ListItemSecondaryAction, IconButton } from '@material-ui/core';
import React from 'react'
import { MenuItem } from './models/RealmDataModels';
import { toEuro } from './utils/FormatUtils';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';


interface MenuItemListItemProps {
  item: MenuItem;
  onClick: (item: MenuItem) => void;
}

export const MenuItemListItem = (props: MenuItemListItemProps) => {
  const classes = useStyles();

  const { item, onClick } = props;
  return (
    <ListItem button key={`item-${item.name}`} onClick={() => onClick(item)}>
      <ListItemAvatar>
        <Avatar alt={item.name} src={item.images[0]} >
          {item.name.substr(0, 1)}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <React.Fragment>
            <Typography
              component="span"
              variant="body2"
              className={classes.inline}
              color="textPrimary"
            >
              {item.name}
            </Typography>
            <Typography
              component="span"
              variant="body2"
              className={classes.inline}
              color="textSecondary"
            >
              {` - ${item.itemRef} `}
            </Typography>
          </React.Fragment>
        }
        secondary={toEuro(item.price.valueOf() || 0)} />

      <ListItemSecondaryAction>
        <IconButton edge="end" aria-label="edit" onClick={() => onClick(item)}>
          <EditIcon color="primary" />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  )
}


const useStyles = makeStyles((theme) => ({
  inline: {
    display: 'inline',
  },
}));
