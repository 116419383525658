import { Button, ButtonProps, CircularProgress } from '@material-ui/core'
import DoneIcon from '@material-ui/icons/Done';
import React from 'react'

interface LoadingButtonProps extends ButtonProps {
  isLoading: boolean;
  isDone?: boolean;
}

const LoadingButton = (props: LoadingButtonProps) => {
  const { isLoading, isDone, children, ...buttonProps } = props;

  const getProgressSize = (size: "small" | "medium" | "large" | undefined) => {
    switch (size) {
      case "small":
        return 20

      case "medium":
        return 25

      case "large":
        return 30

      default:
        return 25;
    }
  }

  return (
    <Button {...buttonProps} disabled={isLoading || props.disabled}>
      {isLoading ? <CircularProgress size={getProgressSize(buttonProps.size)} /> : isDone ? <DoneIcon /> : children}
    </Button>
  )
}

export default LoadingButton
