import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Merchant, MerchantBasic } from '../models/RealmDataModels';
import { useAuth } from '../providers/AuthProvider';

interface MerchantsAutocompleteProps {
  value: MerchantOption;
  defaultValue: MerchantOption;
  onChange: (value: MerchantOption) => void;
}

export type MerchantOption = {
  name: string;
  _id: string;
}

const MerchantsAutocomplete = (props: MerchantsAutocompleteProps) => {
  const { value, onChange, defaultValue } = props;
  const auth = useAuth();

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<MerchantOption[]>([]);
  const loading = open && options.length === 0;

  const loadMerchants = async () => {
    const user = auth.getUser();
    if (!user) {
      console.log("No user");
      return;
    };

    const mongodb = user.mongoClient("mongodb-atlas");
    const merchants = mongodb.db("jymba").collection<MerchantBasic>("Merchant");
    const dbMerchants = await merchants.find({}, { projection: { _id: 1, name: 1 }, sort: { name: 1 } });

    return dbMerchants
  }


  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const merchants = await loadMerchants();
      if (active) {
        if (merchants) {

          const merchantList = merchants.map((merch) => ({
            _id: merch._id.toHexString(),
            name: merch.name
          }));
          setOptions([...merchantList, { name: "GLOBAL", _id: "GLOBAL" }]);
        }
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id="Autocomplete-merchants"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) => option._id === value._id}
      onChange={(e, val) => onChange(val || { name: "GLOBAL", _id: "GLOBAL" })}
      value={value}
      defaultValue={defaultValue}
      fullWidth
      getOptionLabel={(option) => option.name}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label="Merchant"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}

export default MerchantsAutocomplete
