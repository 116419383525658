import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Merchant, MenuItem, ItemConfigGroup, MenuItemCategory } from './models/RealmDataModels';
import { useAuth } from './providers/AuthProvider';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Divider, List, ListSubheader, Slide, IconButton, ListItemSecondaryAction, Backdrop } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import MenuItemForm from './MenuItemForm';

import _ from 'lodash';
import { SnackbarInfo } from './OptionGroupForm';
import { TransitionProps } from '@material-ui/core/transitions';
import EditIcon from '@material-ui/icons/Edit';
import { CategoryModal } from './CategoryForm/CategoryModal';
import { MenuItemListItem } from './MenuItemListItem';
import { CategoryOrderList } from './CategoryForm/CategoryOrderList';


const useStyles = makeStyles((theme) => ({
  gridList: {
    width: "100%",
    // height: "auto",
  },
  gridTile: {
    height: 50,
    width: 50,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  categoryTile: {
    background: 'orange'
  },
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: "55vh",
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  inline: {
    display: 'inline',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0
  }
}));


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export type MenuItemFormInput = Omit<MenuItem, '_id'>;

interface MenuFormProps {
  merchant: Merchant;
}

const MenuForm: React.FC<MenuFormProps> = (props) => {
  const { merchant } = props;
  const auth = useAuth();
  const classes = useStyles();

  const [categories, setCategories] = useState<MenuItemCategory[]>([]);
  const [configGroups, setConfigGroups] = useState<ItemConfigGroup[]>([]);
  const [selectedMenuItem, setSelectedMenuItem] = useState<MenuItem>();
  const [itemDialogIsOpen, setItemDialogIsOpen] = useState<boolean>(false);
  const [categoryDialogIsOpen, setCategoryDialogIsOpen] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<MenuItemCategory>();

  const [loading, setLoading] = useState(false);

  const [openSnackbar, setOpenSnackbar] = React.useState<SnackbarInfo>();

  useEffect(() => {
    if (merchant) {
      load();
    }
  }, [merchant]);

  const load = async () => {
    setLoading(true);
    await Promise.all([loadCategories(), loadMenuOptions()])
    setLoading(false);
  }

  const loadCategories = async () => {
    const user = auth.getUser();
    if (!user || merchant._id === undefined) return;

    const mongodb = user.mongoClient("mongodb-atlas");
    const menuItemCategory = mongodb.db("jymba").collection<MenuItemCategory>("MenuItemCategory");

    const dbMenuItemsCategories = await menuItemCategory.find({ merchantId: merchant._id }, { sort: { rank: 1 } });

    setCategories(dbMenuItemsCategories);
  }

  const updatedSelectedCategories = async () => {
    const user = auth.getUser();
    if (!user || merchant._id === undefined || selectedCategory === undefined) return;

    const mongodb = user.mongoClient("mongodb-atlas");
    const menuItemCategory = mongodb.db("jymba").collection<MenuItemCategory>("MenuItemCategory");

    const selectedCat = await menuItemCategory.findOne({ _id: selectedCategory._id  });

    if (selectedCat) {
      setSelectedCategory(selectedCat);
    }
  }

  const loadMenuOptions = async () => {
    const user = auth.getUser();
    if (!user || merchant._id === undefined) return;

    const mongodb = user.mongoClient("mongodb-atlas");
    const configGroupCollection = mongodb.db("jymba").collection<ItemConfigGroup>("ItemConfigGroup");

    const dbConfigGroups = await configGroupCollection.find({ merchantId: merchant._id });

    setConfigGroups(dbConfigGroups);
  }

  const selectAddItem = (category?: MenuItemCategory, menuItem?: MenuItem) => {
    setSelectedCategory(category);
    if (menuItem) {
      setSelectedMenuItem(menuItem);
    } else {
      setSelectedMenuItem(undefined);
    }
    setItemDialogIsOpen(true);
  }

  const onSelectCategory = (category?: MenuItemCategory) => {
    if (category) {
      setSelectedCategory(category);
    } else {
      setSelectedCategory(undefined);
    }
    setCategoryDialogIsOpen(true);
  }


  const handleCloseDialog = () => {
    setItemDialogIsOpen(false);
  };

  const handleSaveDialog = async (success: boolean) => {
    if (success) {
      setItemDialogIsOpen(false);
      setOpenSnackbar({ success, text: 'Updated/Inserted Menu Item' });
      await load();
    }
    else {
      setItemDialogIsOpen(false);
      setOpenSnackbar({ success, text: 'Error while updloading/inserting Menu Item' });
    }
    
    await updatedSelectedCategories();
  }


  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Restaurant Menu
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => onSelectCategory()}
            endIcon={<AddIcon />}>
            Add Category
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        {/* Menu Item List */}
        <Grid item container xs={12} spacing={2}>

          <Grid item xs={8}>
            <Typography variant="h5">
              Menu
            </Typography>
            <Divider />
            <List className={classes.root} subheader={<li />}>
              {categories.map((category) => (
                <li key={`section-${category.title['en']}`} className={classes.listSection}>
                  <ul className={classes.ul}>
                    <ListSubheader style={{ backgroundColor: '#f8c96f', boxShadow: "0px 2px 5px 1px #00000045" }} draggable>
                      <Typography variant="h6" >
                        {`${category.title['en']} - ${category.title['de']}`}
                      </Typography>

                      <Typography variant="subtitle2" gutterBottom>
                        {`${category.desc['en']} - ${category.desc['de']}`}
                      </Typography>

                      <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="edit-category" onClick={() => onSelectCategory(category)}>
                          <EditIcon color="primary" />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListSubheader>

                    {category.items.map((item, idx) => (
                      <React.Fragment key={`${category.path}_${idx}`}>
                        <MenuItemListItem
                          key={category.path + item.name}
                          item={item}
                          onClick={() => selectAddItem(category, item)}
                        />
                        <Divider />
                      </React.Fragment>
                    ))}

                  </ul>
                </li>
              ))}
            </List>
          </Grid>

          <Grid item xs={4}>
            <Typography variant="h5">
              Categories
            </Typography>
            <Divider />

            <CategoryOrderList
              categories={categories}
              onLoading={() => setLoading(true)}
              onUpdatedRanks={async () => await load()}
              classes={classes.root} />
          </Grid>
        </Grid>
      </Grid>

      {/* Dialog */}
      <Dialog fullScreen open={itemDialogIsOpen} onClose={handleCloseDialog}>
        {selectedCategory && (
          <MenuItemForm
            category={selectedCategory}
            merchant={merchant}
            optionGroups={configGroups}
            menuItem={selectedMenuItem}
            handleClose={handleCloseDialog}
            handleSave={handleSaveDialog}
            onUpdateConfigGroups={loadMenuOptions}
          />
        )}
      </Dialog>

      <Dialog fullScreen open={categoryDialogIsOpen} onClose={() => setCategoryDialogIsOpen(false)} TransitionComponent={Transition}>
        <CategoryModal
          nextRank={categories.length}
          handleClose={() => setCategoryDialogIsOpen(false)}
          handleSave={async (success) => {
            setCategoryDialogIsOpen(false);
            if (success) {
              setOpenSnackbar({ success: true, text: "Category created!" });
              await load();
            } else {
              setOpenSnackbar({ success: false, text: "Error while creating category" });
            }
            await updatedSelectedCategories();
          }}
          category={selectedCategory}
          merchant={merchant}
          handleAddMenuItem={(item) => selectAddItem(selectedCategory, item)}
        />
      </Dialog>

      <Snackbar open={openSnackbar !== undefined} autoHideDuration={6000} onClose={() => setOpenSnackbar(undefined)}>
        <Alert onClose={() => setOpenSnackbar(undefined)} severity={openSnackbar?.success ? 'success' : 'error'}>
          {openSnackbar?.text}
        </Alert>
      </Snackbar>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  );
}

export default MenuForm;