import { Box, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Paper, Select, Slider, TextField, Tooltip, Typography } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import {
  KeyboardDatePicker
} from '@material-ui/pickers';
import { useEffect, useState } from 'react';
import { BSON } from 'realm-web';
import LoadingButton from '../LoadingButton';
import { Campaign, CampaignDB, CouponDeliveryType } from '../models/RealmDataModels';
import { useAuth } from '../providers/AuthProvider';
import MerchantsAutocomplete, { MerchantOption } from './MerchantsAutocomplete';


const defaultCoupon: Campaign = {
  _id: new BSON.ObjectId(),
  campaignCode: "CODE",
  campaignStart: new Date(),
  campaignEnd: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
  campaignStatus: "active",
  campaignType: "amountDiscount",
  campaignValue: 2,
  minOrderAmount: 15,
  _createdBy: "1",
  _updatedBy: "1",
  _createdOn: new Date(),
  _updatedOn: new Date(),
  _status: "active",
  _ver: "1.0.0",
  merchantId: "GLOBAL",
  multiuse: false,
  oneTimeCode: false,
  userType: "allUsers",
  deliveryType: 'all'
}

interface CouponFormProps {
  coupon?: Campaign;
  onRefresh?: () => Promise<void>;
}
const marksAmount = [
  {
    value: 2,
    label: '2€',
  },
  {
    value: 4,
    label: '4€',
  },
  {
    value: 6,
    label: '6€',
  },
  {
    value: 8,
    label: '8€',
  },
  {
    value: 10,
    label: '10€',
  },
  {
    value: 12,
    label: '12€',
  },
  {
    value: 14,
    label: '14€',
  },
  {
    value: 16,
    label: '16€',
  },
  {
    value: 18,
    label: '18€',
  },
  {
    value: 20,
    label: '20€',
  },
];

const marksPercent = [
  {
    value: 0.05,
    label: '5%',
  },
  {
    value: 0.1,
    label: '10%',
  },
  {
    value: 0.15,
    label: '15%',
  },
  {
    value: 0.2,
    label: '20%',
  },
  {
    value: 0.25,
    label: '25%',
  },
  {
    value: 0.3,
    label: '30%',
  },
  {
    value: 0.35,
    label: '35%',
  },
  {
    value: 0.4,
    label: '40%',
  },
  {
    value: 0.45,
    label: '45%',
  },
  {
    value: 0.5,
    label: '50%',
  },

];

const CouponForm = (props: CouponFormProps) => {
  const auth = useAuth();
  const [couponValues, setCouponValues] = useState(props.coupon);
  const [isLoading, setIsLoading] = useState(false);
  const [merchantOption, setMerchantOption] = useState<MerchantOption>();

  const isNewCoupon = props.coupon === undefined;

  useEffect(() => {
    if (props.coupon) {
      setCouponValues(props.coupon);
      loadMerchant(props.coupon);
    } else {
      setCouponValues(defaultCoupon);
      loadMerchant(defaultCoupon);
    }

  }, [props.coupon]);

  const loadMerchant = async (campaign: Campaign): Promise<void> => {
    const user = auth.getUser();
    if (!user) {
      return;
    };

    if (campaign.merchantId === "GLOBAL") {
      setMerchantOption(
        {
          _id: "GLOBAL",
          name: "GLOBAL"
        }
      )
    } else {
      const mongodb = user.mongoClient("mongodb-atlas");
      const merchants = mongodb.db("jymba").collection<MerchantOption>("Merchant");
      const dbMerchant = await merchants.findOne({ _id: new BSON.ObjectId(campaign.merchantId) }, { projection: { _id: 1, name: 1 } });

      if (dbMerchant) {
        setMerchantOption(dbMerchant);
      }

    }
  }
  if (!couponValues) return null;

  const handleDelete = async () => {
    const user = auth.getUser();
    if (!user) {
      console.log("No user");
      return;
    };

    setIsLoading(true);

    const mongodb = user.mongoClient("mongodb-atlas");
    const campaignColl = mongodb.db("jymba").collection<Campaign | CampaignDB>("Campaign");

    await campaignColl.deleteOne({ _id: couponValues._id });
    if (props.onRefresh) {
      await props.onRefresh();
    }
    setIsLoading(false);

  }

  const handleSubmit = async () => {
    const user = auth.getUser();
    if (!user) {
      console.log("No user");
      return;
    };

    setIsLoading(true);

    const mongodb = user.mongoClient("mongodb-atlas");
    const campaignColl = mongodb.db("jymba").collection<Campaign | CampaignDB>("Campaign");

    const transformedCampaignVals = {
      ...couponValues,
      campaignValue: new BSON.Double(couponValues.campaignValue),
      minOrderAmount: new BSON.Double(couponValues.minOrderAmount)
    };

    if (isNewCoupon) {
      await campaignColl.insertOne(transformedCampaignVals);
    } else {
      await campaignColl.updateOne({ _id: couponValues._id }, transformedCampaignVals);
    }
    if (props.onRefresh) {
      await props.onRefresh();
    }
    setIsLoading(false);
  }

  return (
    <form id={"coupon-form-1"}>
      <Grid container spacing={4}>

        {/* CAMPAIGNCODE */}
        <Grid item sm={6} xs={12}>
          <TextField
            id="campaignCode"
            label="CampaignCode"
            variant="outlined"
            required
            fullWidth
            value={couponValues.campaignCode}
            onChange={(event) => setCouponValues({ ...couponValues, campaignCode: event.target.value })}
          />
        </Grid>
        {/* MERCHANT */}
        <Grid item sm={6} xs={12}>
          {merchantOption && (
            <MerchantsAutocomplete
              value={merchantOption}
              defaultValue={merchantOption}
              onChange={(merchOpt) => {
                setMerchantOption(merchOpt)
                setCouponValues({ ...couponValues, merchantId: merchOpt._id });
              }}
            />
          )}
          {couponValues.merchantId}
        </Grid>

        {/* START */}
        <Grid item sm={6} xs={12}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            fullWidth
            label="Campaign Start"
            value={couponValues.campaignStart}
            onChange={(newDate) => setCouponValues({ ...couponValues, campaignStart: newDate || new Date() })}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </Grid>

        {/* END */}
        <Grid item sm={6} xs={12}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            fullWidth
            label="Campaign End"
            value={couponValues.campaignEnd}
            onChange={(newDate) => setCouponValues({ ...couponValues, campaignEnd: newDate || new Date() })}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </Grid>

        {/* USER TYPE */}
        <Grid item sm={6} xs={12}>
          <FormControl fullWidth >
            <InputLabel>User Type</InputLabel>
            <Select
              labelId="user-type-select-label"
              id="user-type-select"
              value={couponValues.userType}
              onChange={(event) => setCouponValues({ ...couponValues, userType: event.target.value as ("allUsers" | "newUsers") })}
            >
              <MenuItem value="allUsers">All Users</MenuItem>
              <MenuItem value="newUsers">New Users</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* DELIVERY TYPE */}
        <Grid item sm={6} xs={12}>
          <FormControl fullWidth >
            <InputLabel>Delivery Type</InputLabel>
            <Select
              labelId="delivery-type-select-label"
              id="delivery-type-select"
              value={couponValues.deliveryType}
              onChange={(event) => setCouponValues({ ...couponValues, deliveryType: event.target.value as CouponDeliveryType })}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="delivery">Delivery</MenuItem>
              <MenuItem value="pickup">Pickup</MenuItem>
              <MenuItem value="inohuse">Inhouse</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* ONE TIME CODE */}
        {couponValues.userType === 'newUsers' ? (
          <Grid item sm={6} xs={12}>
            <Tooltip title="If this is active the same user can use this coupon multiple times">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={couponValues.multiuse}
                    onChange={(event) => setCouponValues({ ...couponValues, multiuse: event.target.checked })}
                    name="multiuse"
                    color="primary"
                  />
                }
                label="Multiuse"
              />
            </Tooltip>
          </Grid>
        ) : (
          <Grid item sm={6} xs={12}>
            <Tooltip title="If this is active the coupon can only be used once">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={couponValues.oneTimeCode}
                    onChange={(event) => setCouponValues({ ...couponValues, oneTimeCode: event.target.checked })}
                    name="OneTimeCode"
                    color="primary"
                  />
                }
                label="One Time Code"
              />
            </Tooltip>
          </Grid>
        )}



        {/* CAMPAIGN TYPE */}
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" flexDirection="column">
            <Box mb={1} mt={2}>
              <Typography variant="body1">
                Campaign Type
              </Typography>
            </Box>

            <Paper>
              <ToggleButtonGroup
                value={couponValues.campaignType}
                exclusive
                onChange={(e, newVal) => setCouponValues({ ...couponValues, campaignType: newVal })}
              >
                <ToggleButton value="amountDiscount">
                  Amount Discount
                </ToggleButton>
                <ToggleButton value="percentDiscount">
                  Percentage Discount
                </ToggleButton>
              </ToggleButtonGroup>
            </Paper>
          </Box>
        </Grid>



        {/* COUPON VALUE */}
        <Grid item sm={6} xs={12}>
          <Box p={2}>
            <Typography id="coupon-value" gutterBottom>
              Coupon Value
            </Typography>
            <Slider
              value={couponValues.campaignValue}
              onChangeCommitted={(event, newValue) => setCouponValues({ ...couponValues, campaignValue: newValue as number })}
              defaultValue={couponValues.campaignType === 'amountDiscount' ? 2 : 0.1}
              getAriaValueText={(value) => `${value} ${couponValues.campaignType === 'amountDiscount' ? '€' : '%'}`}
              valueLabelDisplay="on"
              valueLabelFormat={(value) => `${couponValues.campaignType === 'amountDiscount' ? value : value * 100} ${couponValues.campaignType === 'amountDiscount' ? '€' : '%'}`}
              step={couponValues.campaignType === 'amountDiscount' ? 1 : 0.05}
              marks={couponValues.campaignType === 'amountDiscount' ? marksAmount : marksPercent}
              min={couponValues.campaignType === 'amountDiscount' ? 1 : 0}
              max={couponValues.campaignType === 'amountDiscount' ? 20 : 0.5}
            />
          </Box>
        </Grid>

        {/* MIN ORDER AMOUNT */}
        <Grid item sm={6} xs={12}>
          <Box p={2}>
            <Typography id="order-amount" gutterBottom>
              Min. Order Amount
            </Typography>
            <Slider
              value={couponValues.minOrderAmount}
              onChangeCommitted={(event, newValue) => setCouponValues({ ...couponValues, minOrderAmount: newValue as number })}
              defaultValue={10}
              getAriaValueText={(value) => `${value}€`}
              valueLabelDisplay="on"
              valueLabelFormat={(value) => `${value}€`}
              step={1}
              marks={marksAmount}
              min={0}
              max={20}
            />
          </Box>
        </Grid>

        <Grid item sm={6} xs={12}>
          {!isNewCoupon && (
            <Box display="flex" justifyContent="flex-start">

              <LoadingButton
                variant="contained"
                color="default"
                id="coupon-delete"
                onClick={() => handleDelete()}
                isLoading={isLoading}
              >
                Delete
              </LoadingButton>
            </Box>
          )}
        </Grid>

        <Grid item sm={6} xs={12}>
          <Box display="flex" justifyContent="flex-end">

            <LoadingButton
              variant="contained"
              color="primary"
              id="coupon-form"
              onClick={() => handleSubmit()}
              isLoading={isLoading}
            >
              {isNewCoupon ? "Create Coupon" : "Update Coupon"}
            </LoadingButton>
          </Box>
        </Grid>



      </Grid>

    </form>
  )
}

export default CouponForm
