import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
  Merchant,
  MenuItem,
  ItemConfigGroup,
  MenuItemCategory,
} from "./models/RealmDataModels";
import { useAuth } from "./providers/AuthProvider";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import { BSON } from "realm-web";
import _ from "lodash";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import Resizer from "react-image-file-resizer";
import { nanoid } from "nanoid";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import OptionGroupForm from "./OptionGroupForm";
import EditIcon from "@material-ui/icons/Edit";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { DBAutoComplete } from "./DBAutoComplete";
import AddIcon from "@material-ui/icons/Add";
import Divider from "@material-ui/core/Divider";
import Badge from "@material-ui/core/Badge";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { ImageUpload } from "./ImageUpload";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export type MenuItemFormInput = Omit<MenuItem, "_id">;

export const defaultMenuItem: MenuItemFormInput = {
  allergens: [],
  ingredients: [],
  name: "",
  price: new BSON.Double(0),
  itemCode: "",
  isConfigurable: false,
  images: [],
  optionGroups: [],
  desc: "",
  itemRef: "",
  isVegan: false,
  isVegetarian: false,
  // Default Values
  _ver: "1.0",
  _createdBy: "1",
  _updatedBy: "1",
  _createdOn: new Date(),
  _updatedOn: new Date(),
  _status: "active",
};

interface MenuItemProps {
  menuItem?: MenuItem;
  category: MenuItemCategory;
  optionGroups?: ItemConfigGroup[];
  merchant: Merchant;
  handleClose: () => void;
  handleSave: (success: boolean) => void;
  onUpdateConfigGroups: () => void;
}

const filter = createFilterOptions<string>();

const MenuItemForm: React.FC<MenuItemProps> = (props) => {
  const {
    menuItem,
    optionGroups,
    handleClose,
    handleSave,
    merchant,
    onUpdateConfigGroups,
    category,
  } = props;
  const auth = useAuth();
  const classes = useStyles();

  const [itemFormValues, setItemFormValues] =
    useState<MenuItemFormInput>(defaultMenuItem);
  const [loading, setLoading] = useState(false);
  const [changedValues, setChangedValues] = useState(false);
  const [selectedOptionGroups, setSelectedOptionGroups] = useState<
    ItemConfigGroup[]
  >([]);

  // Option Group
  const [optionGroupSearch, setOptionGroupSearch] = useState<string>();
  const [filteredOptionGroups, setFilteredOptionGroups] = useState<
    ItemConfigGroup[]
  >([]);
  const [selectedOptGroupForEdit, setSelectedOptGroupForEdit] =
    useState<ItemConfigGroup>();

  useEffect(() => {
    if (!optionGroups) {
      return;
    }
    const sortedOptionGroups = optionGroups.sort((a, b) =>
      a.title.localeCompare(b.title)
    );

    if (!optionGroupSearch) {
      setFilteredOptionGroups(sortedOptionGroups);
    } else {
      const filtered = sortedOptionGroups.filter((og) =>
        og.title.toLowerCase().includes(optionGroupSearch.toLowerCase())
      );
      setFilteredOptionGroups(filtered);
    }
  }, [optionGroupSearch, optionGroups]);

  useEffect(() => {
    if (menuItem) {
      console.log("menuItem", menuItem)
      setItemFormValues(menuItem);
      if (optionGroups && menuItem.optionGroups) {
        const selectedOptionGroups: Array<ItemConfigGroup> = [];
        menuItem.optionGroups.forEach((optionId) => {
          const group = optionGroups.find((og) => og._id.equals(optionId));
          if (group) {
            selectedOptionGroups.push(group);
          }
        });
        setSelectedOptionGroups(selectedOptionGroups);
      }
    } else {
      setItemFormValues(defaultMenuItem);
      setSelectedOptionGroups([]);
    }
  }, [menuItem, optionGroups]);

  useEffect(() => {
    if (menuItem) {
      const different =
        JSON.stringify(menuItem) !== JSON.stringify(itemFormValues);
      const different2 =
        JSON.stringify(menuItem.images) !==
        JSON.stringify(itemFormValues.images);
      setChangedValues(different || different2);
    } else {
      setChangedValues(true);
    }
  }, [itemFormValues, menuItem]);

  const loadCategories = async () => {
    if (!auth.user) return [];

    const mongodb = auth.user.mongoClient("mongodb-atlas");
    const menuItemsCategoryColl = mongodb
      .db("jymba")
      .collection<MenuItemCategory>("MenuItemCategory");
    const dbCategories = await menuItemsCategoryColl.find(
      { merchantId: merchant._id },
      { projection: { title: 1 } }
    );
    const allCategories = dbCategories.map((mI) => mI.title);

    return allCategories;
  };

  const loadAllergens = async () => {
    if (!auth.user) return [];

    const mongodb = auth.user.mongoClient("mongodb-atlas");
    const categoryColl = mongodb
      .db("jymba")
      .collection<MenuItemCategory>("MenuItemCategory");
    const dbMenuItems = await categoryColl.find(
      { merchantId: merchant._id },
      { projection: { "items.allergens": 1 } }
    );
    const allMenuItems = dbMenuItems
      .map((cat) => cat.items)
      .reduce((prev, cur) => [...prev, ...cur], []);
    const allAllergens = allMenuItems
      .map((mI) => mI.allergens)
      .reduce((prev, cur) => [...prev, ...cur], []);
    const uniqAllergens = [...new Set(allAllergens.flat(1))];

    return uniqAllergens;
  };

  const loadIngredients = async () => {
    if (!auth.user) return [];

    const mongodb = auth.user.mongoClient("mongodb-atlas");
    const categoryColl = mongodb
      .db("jymba")
      .collection<MenuItemCategory>("MenuItemCategory");
    const dbMenuItems = await categoryColl.find(
      { merchantId: merchant._id },
      { projection: { "items.ingredients": 1 } }
    );
    const allMenuItems = dbMenuItems
      .map((cat) => cat.items)
      .reduce((prev, cur) => [...prev, ...cur], []);
    const allIngredients = allMenuItems
      .map((mI) => mI.ingredients)
      .reduce((prev, cur) => [...prev, ...cur], []);
    const uniqIngredients = [...new Set(allIngredients.flat(1))];

    return uniqIngredients;
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setItemFormValues({
      ...itemFormValues,
      isConfigurable: event.target.checked,
    });
  };

  const resizeFile = (file: any) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        256,
        256,
        "PNG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  const upload = async (img: string, idx: number) => {
    const storage = await auth.getFirebaseStorage();
    if (storage) {
      try {
        // const resizedImg = await resizeFile(img);

        const fileName = `${merchant.merchantCode}_${
          itemFormValues.name
        }_${idx}_${new Date().toISOString()}`;
        const reference = storage.ref(
          `/merchant/${merchant.merchantCode}/${fileName}`
        );
        await reference.putString(img as string, "data_url");
        const url = await reference.getDownloadURL();

        return url as string;
      } catch (e: unknown) {
        console.error(e);
        if (e instanceof Error) {
          throw new Error("Error on uploadImgToFirebase:" + e.message);
        }
      }
    } else {
      throw new Error("Connection to firebase failed");
    }
  };

  const uploadImgsToFirebase = async () => {
    const urls = await Promise.all(
      itemFormValues.images.map(async (img, idx) => {
        if (img.startsWith("https://") || img.startsWith("http://")) {
          return img;
        }
        // resize & upload
        return upload(img, idx);
      })
    );

    return urls;
  };

  const handleDelete = async () => {
    if (!auth.user) return;
    setLoading(true);
    try {
      // Get mongo client
      const mongodb = auth.user.mongoClient("mongodb-atlas");
      const menuItemCategoryColl = mongodb
        .db("jymba")
        .collection<MenuItemCategory>("MenuItemCategory");

      await menuItemCategoryColl.updateOne(
        { _id: category._id },
        { $pull: { items: { _id: menuItem?._id } } }
      );

      handleSave(true);
    } catch (e) {
      console.log("e.message", e);
      handleSave(false);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (!auth.user) return;
    setLoading(true);

    // Get mongo client
    const mongodb = auth.user.mongoClient("mongodb-atlas");
    const merchantsCollection = mongodb
      .db("jymba")
      .collection<Merchant>("Merchant");
    const menuItemCategoryColl = mongodb
      .db("jymba")
      .collection<MenuItemCategory>("MenuItemCategory");

    try {
      // upload image
      let imgUrls = [];
      try {
        imgUrls = await uploadImgsToFirebase();
      } catch (e) {
        handleSave(false);
        return;
      }

      if (menuItem) {
        const newDoc = {
          ...itemFormValues,
          images: imgUrls,
          optionGroups: selectedOptionGroups.map((og) => og._id),
          price:
            typeof itemFormValues.price === "number"
              ? new BSON.Double(itemFormValues.price as number)
              : itemFormValues.price,
        };

        await menuItemCategoryColl.updateOne(
          { _id: category._id, "items._id": menuItem._id },
          { $set: { "items.$": newDoc } }
        );

        //await menuItemsCollection.updateOne({ _id: menuItem._id }, newDoc);
        handleSave(true);
      } else {
        console.log("category", category.title["en"]);
        // upload menuItem
        await menuItemCategoryColl.updateOne(
          { _id: category._id },
          {
            $push: {
              items: {
                ...itemFormValues,
                _id: new BSON.ObjectID(),
                images: imgUrls,
                itemCode: _.toUpper(`I-${nanoid(6)}`),
                optionGroups: selectedOptionGroups.map((og) => og._id),
                price:
                  typeof itemFormValues.price === "number"
                    ? new BSON.Double(itemFormValues.price as number)
                    : itemFormValues.price,
              },
            },
          }
        );

        handleSave(true);
      }
    } catch (e) {
      console.log("e.message", e);
      handleSave(false);
    } finally {
      setLoading(false);
    }
  };

  const renderConfiguration = () => {
    if (!itemFormValues.isConfigurable) return;
    return (
      <React.Fragment>
        <Grid item xs={12} sm={12}>
          <Typography variant="subtitle1">Select Option Groups</Typography>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            options={optionGroups ?? []}
            value={selectedOptionGroups}
            disableCloseOnSelect
            onChange={(e, values) => setSelectedOptionGroups([...values])}
            getOptionLabel={(option) => option.title}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.title}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Option Groups"
                placeholder=""
              />
            )}
          />
        </Grid>
      </React.Fragment>
    );
  };

  const onEditConfigGroup = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    option?: ItemConfigGroup
  ) => {
    event.stopPropagation();
    event.preventDefault();
    setSelectedOptGroupForEdit(option);
  };

  const renderRightSide = () => {
    return (
      <React.Fragment>
        <Grid item xs={12} sm={12}>
          <TextField
            id="outlined-basic"
            label="Search..."
            variant="outlined"
            fullWidth
            size="small"
            onChange={(e) => setOptionGroupSearch(e.target.value)}
          />

          <Paper
            variant="outlined"
            square
            style={{ maxHeight: 200, overflow: "auto" }}
            className={classes.paper}
          >
            <List dense>
              <ListItem
                role={undefined}
                dense
                button
                onClick={(e) => {
                  onEditConfigGroup(e);
                }}
              >
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                <ListItemText id="add-op" primary="Add option group"  />
              </ListItem>
              <Divider />
              {filteredOptionGroups.map((option, idx) => {
                return (
                  <ListItem
                    key={option.title + idx}
                    dense
                    button
                    selected={option === selectedOptGroupForEdit}
                    onClick={(e) => {
                      onEditConfigGroup(e, option);
                    }}
                  >
                    <ListItemText id={option.title} primary={option.title + ' - (' + option._id.toHexString() + ')'} secondary={option.desc} />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="comments">
                        <EditIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          </Paper>
        </Grid>

        {/* FORM */}
        <OptionGroupForm
          itemConfigGroup={selectedOptGroupForEdit}
          onAddedGroup={onUpdateConfigGroups}
          merchant={merchant}
        />
      </React.Fragment>
    );
  };

  const handleUploadClick = async (
    event: React.ChangeEvent<HTMLInputElement>,
    idx: number
  ) => {
    if (event.target.files) {
      var file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      const resizedImg = await resizeFile(file);

      const imgs = itemFormValues.images;
      imgs[idx] = resizedImg as string;
      setItemFormValues({ ...itemFormValues, images: imgs });
    }
  };

  const handleDeleteImageClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    idx: number
  ) => {
    event.stopPropagation();
    event.preventDefault();
    const imgs = itemFormValues.images;
    imgs.splice(idx, 1);
    setItemFormValues({ ...itemFormValues, images: imgs });
  };

  return (
    <React.Fragment>
      <Grid container spacing={0}>
        {/* LEFT */}
        <Grid item xs={12} sm={8} className={classes.left}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Add/Edit Menu Item
              </Typography>
              <Badge
                color="secondary"
                overlap="circular"
                badgeContent=" "
                variant="dot"
                invisible={!changedValues}
              >
                <Button autoFocus color="inherit" onClick={handleSubmit}>
                  save
                </Button>
              </Badge>
            </Toolbar>
          </AppBar>
          <Paper variant="outlined" square className={classes.paper}>
            <Grid container spacing={2}>
              {/* ITEM REF */}
              <Grid item xs={12} sm={2}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="itemRef"
                  name="itemRef"
                  label="Item Ref"
                  focused={itemFormValues.itemRef !== ""}
                  value={itemFormValues.itemRef}
                  onChange={(e) =>
                    setItemFormValues({
                      ...itemFormValues,
                      itemRef: e.target.value,
                    })
                  }
                  fullWidth
                />
              </Grid>
              {/* NAME */}
              <Grid item xs={12} sm={8}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  name="name"
                  label="Name"
                  focused={itemFormValues.name !== ""}
                  value={itemFormValues.name}
                  onChange={(e) =>
                    setItemFormValues({
                      ...itemFormValues,
                      name: e.target.value,
                    })
                  }
                  fullWidth
                />
              </Grid>
              {/* PRICE */}
              <Grid item xs={12} sm={2}>
                <TextField
                  autoFocus
                  type="number"
                  margin="dense"
                  id="price"
                  name="price"
                  label="Price"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">€</InputAdornment>
                    ),
                  }}
                  fullWidth
                  focused={itemFormValues.price.value !== 0}
                  value={itemFormValues.price}
                  onChange={(e) =>
                    setItemFormValues({
                      ...itemFormValues,
                      price: new BSON.Double(parseFloat(e.target.value)),
                    })
                  }
                />
              </Grid>
              {/* DESCRIPTION */}
              <Grid item xs={12} sm={12}>
                <TextField
                  multiline
                  margin="dense"
                  id="desc"
                  name="desc"
                  label="Description"
                  focused={itemFormValues.desc !== ""}
                  value={itemFormValues.desc}
                  onChange={(e) =>
                    setItemFormValues({
                      ...itemFormValues,
                      desc: e.target.value,
                    })
                  }
                  fullWidth
                />
              </Grid>

              {/* ALLERGENS */}
              <Grid item xs={12} sm={12}>
                <DBAutoComplete
                  id="allergens"
                  label="Allergens"
                  value={itemFormValues.allergens}
                  onChange={(newValue) =>
                    setItemFormValues({
                      ...itemFormValues,
                      allergens: newValue as string[],
                    })
                  }
                  optionLoadFunction={loadAllergens}
                  multiple
                />
              </Grid>
              {/* INGRIDIENS */}
              <Grid item xs={12} sm={12}>
                <DBAutoComplete
                  id="Ingredients"
                  label="Ingredients"
                  value={itemFormValues.ingredients}
                  onChange={(newValue) =>
                    setItemFormValues({
                      ...itemFormValues,
                      ingredients: newValue as string[],
                    })
                  }
                  optionLoadFunction={loadIngredients}
                  multiple
                />
              </Grid>

              {/* Vegetarian/Vegan */}
              <Grid item xs={12} sm={12}>
                <Grid
                  container
                  spacing={0}
                  justifyContent="center"
                  alignItems="flex-start"
                  direction="column"
                >
                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      value={itemFormValues.isVegetarian}
                      control={
                        <Checkbox
                          checked={itemFormValues.isVegetarian}
                          onChange={(e, checked) =>
                            setItemFormValues({
                              ...itemFormValues,
                              isVegetarian: checked,
                            })
                          }
                          name="isVegetarianChecked"
                        />
                      }
                      label="Vegetarian"
                      labelPlacement="end"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      value={itemFormValues.isVegan}
                      control={
                        <Checkbox
                          checked={itemFormValues.isVegan}
                          onChange={(e, checked) =>
                            setItemFormValues({
                              ...itemFormValues,
                              isVegan: checked,
                            })
                          }
                          name="isVeganChecked"
                        />
                      }
                      label="Vegan"
                      labelPlacement="end"
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* IMAGE */}
              <Grid item xs={12} sm={12}>
                <Typography>Images</Typography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <ImageUpload
                  id="menu-item-image"
                  idx={0}
                  img={itemFormValues.images[0]}
                  handleUploadClick={handleUploadClick}
                  handleDeleteImageClick={handleDeleteImageClick}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <ImageUpload
                  id="menu-item-image"
                  idx={1}
                  img={itemFormValues.images[1]}
                  handleUploadClick={handleUploadClick}
                  handleDeleteImageClick={handleDeleteImageClick}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <ImageUpload
                  id="menu-item-image"
                  idx={2}
                  img={itemFormValues.images[2]}
                  handleUploadClick={handleUploadClick}
                  handleDeleteImageClick={handleDeleteImageClick}
                />
              </Grid>

              {/* CONFIGURABLE */}
              <Grid item xs={12} sm={8}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={itemFormValues.isConfigurable}
                      onChange={handleSwitchChange}
                      name="isConfigurable"
                      color="primary"
                    />
                  }
                  label="Is Configurable"
                  labelPlacement="end"
                />
              </Grid>

              {renderConfiguration()}
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Button
                style={{ marginTop: 50 }}
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
                disabled={!menuItem}
                onClick={() => handleDelete()}
              >
                Delete
              </Button>
            </Grid>
          </Paper>
        </Grid>

        {/* RIGHT */}
        <Grid item xs={12} sm={4} className={classes.right}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Typography variant="h6" className={classes.title}>
                Option Groups
              </Typography>
            </Toolbar>
          </AppBar>
          <Paper variant="outlined" square className={classes.paper}>
            <Grid container spacing={2}>
              {renderRightSide()}
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  );
};

export default MenuItemForm;

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  paper: {
    padding: 10,
    height: "100%",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  input: {
    display: "none",
  },
  imageContainer: {
    borderRadius: 10,
    width: 256,
    aspectRatio: "1",
  },
  image: {
    borderRadius: 10,
    width: "100%",
    aspectRatio: "1",
    boxShadow: "7px 4px 15px -1px rgba(0,0,0,0.35)",
  },
  deleteImage: {
    position: "relative",
    right: -10,
    top: -10,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  right: {
    padding: 5,
  },
  left: {
    padding: 5,
  },
  optionList: {
    width: "100%",
    position: "relative",
    overflow: "auto",
    maxHeight: 400,
  },
  listHeader: {
    backgroundColor: "white",
  },
  optionGroupsContainer: {
    padding: 10,
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  optionGroupsEditContainer: {
    padding: 10,
  },
}));
