import { AppBar, Button, Checkbox, Dialog, IconButton, Slide, Toolbar, Tooltip, Typography } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@material-ui/icons/Close';
import { DataGrid, GridColDef, GridRenderCellParams, GridRowParams, GridToolbarContainer, GridToolbarExport, GridValueFormatterParams, GridValueGetterParams, MuiEvent } from '@mui/x-data-grid';
import React from 'react';
import { OrderWithMerchName } from '../models/RealmDataModels';
import CheckIcon from '@material-ui/icons/Check';


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface OrdersGridProps {
  orders: OrderWithMerchName[],
  loading: boolean,
  showMerchantName?: boolean;
}
const columns: GridColDef[] = [
  { field: '_createdOn', headerName: 'Datum', width: 120, valueFormatter: (params: GridValueFormatterParams) => (params.value as Date).toLocaleDateString() },
  { field: 'orderCode', headerName: 'O-Code', width: 130 },
  {
    field: 'payment.method', headerName: 'Zahlart', width: 125,
    valueGetter: (params: GridValueGetterParams) => `${params.row.payment?.method || " - "}`,
    valueFormatter: (params: GridValueFormatterParams) => {
      if (params.value === "cash") {
        return "Bar";
      }
      if (params.value === "online" || params.value === "paypal" || params.value === "stripe") {
        return "Online";
      }
      if (params.value === "card") {
        return "Karte";
      }
      return "Weitere"
    },
  },
  {
    field: 'payment.status', headerName: 'Payment Status', flex: 1,
    valueGetter: (params: GridValueGetterParams) => `${params.row.payment?.status || " - "}`,
    valueFormatter: (params: GridValueFormatterParams) => {
      return params.value;
    },
  },
  {
    field: 'delivery', headerName: 'Liefertyp', flex: 1,
    valueGetter: (params: GridValueGetterParams) => `${params.row.delivery?.type || ""}`,
    valueFormatter: (params: GridValueFormatterParams) => {
      if (params.value === "pickup") {
        return "Abholung";
      }
      if (params.value === "delivery") {
        return "Lieferung";
      }
      if (params.value === "dining") {
        return "In Haus";
      }
      return "Andere"
    },
  },
  // {
  //   field: 'servicefees', headerName: 'Servicegebühr', flex: 1,
  //   valueFormatter: (params: GridValueFormatterParams) => `${(params.value as number).toFixed(2).replace('.', ',')}`,
  //   valueGetter: (params: GridValueGetterParams) => {
  //     return 0;
  //   },
  // },
  {
    field: 'finalPrice', headerName: 'Preis', width: 110,
    valueFormatter: (params: GridValueFormatterParams) => `${(params.value as number).toFixed(2).replace('.', ',')}`,
    valueGetter: (params: GridValueGetterParams) => {
      if (params.row.payment?.method === 'paypal') {
        return params.row.totalPrice - Math.round((0.35 + params.row.totalPrice * 0.0249 + Number.EPSILON) * 100) / 100;
      }
      else {
        return params.row.totalPrice;
      }
    },
  },
  {
    field: 'hasSeen',
    disableExport: true,
    headerName: 'Seen',
    width: 65,
    disableColumnMenu: true,
    disableReorder: true,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      const value = (params.value as boolean)
      if(value)
        return <CheckIcon style={{color: 'green'}} />

        return <CloseIcon style={{color: 'lightgray'}}/>;
    }
      // <Checkbox
      //   checked={}
      //   color="primary"
      // />
    
  },
  {
    field: 'hasPrinted',
    disableExport: true,
    headerName: 'Printed',
    width: 78,
    disableColumnMenu: true,
    disableReorder: true,
    sortable: false,
     renderCell: (params: GridRenderCellParams) => {
      const value = (params.value as boolean)
      if(value)
        return <CheckIcon style={{color: 'green'}} />

        return <CloseIcon style={{color: 'lightgray'}}/>;
    }
  }
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  }),
);


const OrdersGrid = (props: OrdersGridProps) => {
  const classes = useStyles();

  const { orders, loading, showMerchantName } = props;

  const [selectedOrder, setSelectedOrder] = React.useState<any>();

  const handleClose = () => {
    setSelectedOrder(undefined);
  };

  const merchCol: GridColDef = {
    field: 'merchantName',
    headerName: 'Merchant',
    width: 210,
    renderCell: (params) => (<Tooltip title={params.value?.toString() || ''}><Typography>{params.value?.toString() || ''}</Typography></Tooltip>)
  };


  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  return (
    <>
      <div style={{ height: 700, width: '100%' }}>
        <DataGrid
          sortModel={[{ field: '_createdOn', sort: 'desc' }]}
          loading={loading}
          rowHeight={35}
          getRowId={(row) => row.orderCode}
          rows={orders}
          columns={showMerchantName ? [merchCol, ...columns] : [...columns]}
          components={{
            Toolbar: CustomToolbar,
          }}
          onRowClick={(params: GridRowParams, event: MuiEvent<React.SyntheticEvent<Element, Event>>) => {
            console.log(params);
            event.defaultMuiPrevented = true;
            setSelectedOrder(params.row);
          }}
        />
      </div>

      <Dialog fullScreen open={selectedOrder !== undefined} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {selectedOrder?.orderCode || ""}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              close
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent dividers={true}>
          <pre>{JSON.stringify(selectedOrder, null, 2)}</pre>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default OrdersGrid
