import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { makeStyles, Theme, createStyles, Typography, Button, Grid, TextField, Slider, FormControlLabel, Switch, Chip, Divider, Box } from '@material-ui/core';
import { MenuItemCategory, Merchant } from '../models/RealmDataModels';
import { useAuth } from '../providers/AuthProvider';
import { BSON } from 'realm-web';
import { getTimeStringForMinutes } from '../utils/TimeUtils';
import { Autocomplete } from '@material-ui/lab';
import { HandleSave } from './CategoryModal';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import DeleteIcon from '@material-ui/icons/Delete';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    paper: {
      padding: 10,
      height: '100%',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    }
  }),
);

export const defaultCategory: MenuItemCategory = {
  title: {},
  desc: {},
  activeFrom: 0,
  activeTo: 1440,
  activeDays: [0, 1, 2, 3, 4, 5, 6],
  path: "",
  rank: 0,
  items: [],
  tags: { en: [], de: [] },

  _partition: "",
  merchantId: new BSON.ObjectID(),

  // Default Values
  _createdBy: '1',
  _updatedBy: '1',
  _createdOn: new Date(),
  _updatedOn: new Date(),
  _status: 'active',
  _id: new BSON.ObjectId()
}


interface CategoryFormProps {
  category?: MenuItemCategory;
  handleSave: (success: boolean) => void;
  nextRank: number;
  merchant: Merchant;
}

export const CategoryForm = forwardRef<HandleSave, CategoryFormProps>((props, ref) => {
  const auth = useAuth();
  const { category, handleSave, nextRank, merchant } = props;

  const [categoryValues, setCategoryValues] = useState<MenuItemCategory>(defaultCategory);
  const [changedValues, setChangedValues] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (category) {
      setCategoryValues(category);
    } else {
      setCategoryValues({
        ...defaultCategory,
        _id: new BSON.ObjectId(),
        rank: nextRank,
        merchantId: merchant._id,
        _partition: "merchant=" + merchant._id.toHexString()
      });
    }
  }, [category, nextRank]);

  useImperativeHandle(
    ref,
    () => ({
      handleSave() {
        handleSubmit();
      }
    }),
  )

  const handleSubmit = async () => {
    if (!auth.user) return false;

    // Get mongo client
    try {
      const mongodb = auth.user.mongoClient("mongodb-atlas");
      const menuItemCategoryColl = mongodb.db("jymba").collection<MenuItemCategory>("MenuItemCategory");

      const items = categoryValues.items.map((i) => ({ ...i, price: typeof i.price === 'number' ? new BSON.Double(i.price as number) : i.price }))
      const categoryDoc = { ...categoryValues, items };

      // insert cart
      await menuItemCategoryColl.updateOne({ _id: categoryValues._id }, categoryDoc, { upsert: true });

      handleSave(true);
    } catch (e) {
      handleSave(false);
    }
  }


  const handleDelete = async () => {
    if (!auth.user || !category) return;
    setLoading(true);
    try {
      // Get mongo client
      const mongodb = auth.user.mongoClient("mongodb-atlas");
      const menuItemCategoryColl = mongodb.db("jymba").collection<MenuItemCategory>("MenuItemCategory");

      await menuItemCategoryColl.deleteOne(
        { _id: category._id },
      );

      handleSave(true);
    } catch (e) {
      console.log('e.message', e);
      handleSave(false);
    } finally {
      setLoading(false);
    }
  }

  const handleSliderChange = (event: any, newValue: number | number[]) => {
    const vals = newValue as number[];
    setCategoryValues({ ...categoryValues, activeFrom: vals[0], activeTo: vals[1] });
  };

  console.log("tags: ", categoryValues.tags);
  return (
    <Grid container spacing={2} alignContent="center">

      <Grid item xs={12} sm={12}>
        <FormControlLabel
          value="top"
          control={
            <Switch
              color="primary"
              checked={categoryValues._status === "active"}
              onChange={(event) => setCategoryValues({ ...categoryValues, _status: event.target.checked ? "active" : "inactive" })}
            />
          }
          label="Active"
          labelPlacement="top"
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          autoFocus
          margin="dense"
          id="titleDE"
          name="titleDE"
          label="Title Deutsch"
          value={categoryValues.title["de"]}
          onChange={(e) => setCategoryValues({ ...categoryValues, title: { ...categoryValues.title, de: e.target.value } })}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          autoFocus
          margin="dense"
          id="titleEN"
          name="titleEN"
          label="Title English"
          value={categoryValues.title["en"]}
          onChange={
            (e) => setCategoryValues({
              ...categoryValues,
              title: { ...categoryValues.title, en: e.target.value },
              path: `/${(categoryValues.title['en'] || '').replace(/\s/g, "").toLowerCase()}`
            })}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          autoFocus
          margin="dense"
          id="descDE"
          name="descDE"
          label="Description Deutsch"
          value={categoryValues.desc["de"]}
          onChange={(e) => setCategoryValues({ ...categoryValues, desc: { ...categoryValues.desc, de: e.target.value } })}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          autoFocus
          margin="dense"
          id="descEN"
          name="descEN"
          label="Description English"
          value={categoryValues.desc["en"]}
          onChange={(e) => setCategoryValues({ ...categoryValues, desc: { ...categoryValues.desc, en: e.target.value } })}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Box>
          <TextField
            autoFocus
            required
            margin="dense"
            id="path"
            name="path"
            label="Path"
            value={categoryValues.path}
            onChange={(e) => setCategoryValues({ ...categoryValues, path: e.target.value })}
            fullWidth
          />
          <Button onClick={() => setCategoryValues({ ...categoryValues, path: `/${(categoryValues.title['en'] || '').replace(/\s/g, "").toLowerCase()}` })}>
            Generate
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          autoFocus
          margin="dense"
          id="rank"
          disabled
          name="rank"
          label="Rank"
          value={categoryValues.rank}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <Autocomplete
          multiple
          id="tags-de"
          options={[]}
          value={categoryValues.tags?.de || []}
          freeSolo
          onChange={(e, vals: string[]) => { setCategoryValues({ ...categoryValues, tags: { ...categoryValues.tags, de: vals } }) }}
          renderTags={(value: string[], getTagProps) =>
            value.map((option: string, index: number) => (
              <Chip variant="outlined" label={option} {...getTagProps({ index })} />
            ))
          }
          renderInput={(params) => (
            <TextField {...params} variant="standard" label="Tags Deutsch" placeholder="Tags" />
          )}
        />
      </Grid>
      {/* CuisinesEn */}
      <Grid item xs={12} sm={12}>
        <Autocomplete
          multiple
          id="tags-en"
          options={[]}
          value={categoryValues.tags?.en || []}
          freeSolo
          onChange={(e, vals: string[]) => { setCategoryValues({ ...categoryValues, tags: { ...categoryValues.tags, en: vals as string[] } }) }}
          renderTags={(value: string[], getTagProps) =>
            value.map((option: string, index: number) => (
              <Chip variant="outlined" label={option} {...getTagProps({ index })} />
            ))
          }
          renderInput={(params) => (
            <TextField {...params} variant="standard" label="Tags English" placeholder="Tags" />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography id="range-slider" gutterBottom>
          Category available From/To
        </Typography>
        <Slider
          min={0}
          max={1440}
          step={60}
          valueLabelFormat={(x) => getTimeStringForMinutes(x)}
          value={[categoryValues.activeFrom || 0, categoryValues.activeTo || 1440]}
          onChange={handleSliderChange}
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography id="range-slider" gutterBottom>
          Category available on
        </Typography>
        <ToggleButtonGroup value={categoryValues.activeDays} onChange={(e, vals) => { setCategoryValues({ ...categoryValues, activeDays: vals }) }} size="small" color="primary">
          <ToggleButton value={1}>Mon</ToggleButton>
          <ToggleButton value={2}>Tue</ToggleButton>
          <ToggleButton value={3}>Wed</ToggleButton>
          <ToggleButton value={4}>Thu</ToggleButton>
          <ToggleButton value={5}>Fri</ToggleButton>
          <ToggleButton value={6}>Sat</ToggleButton>
          <ToggleButton value={0}>Sun</ToggleButton>
        </ToggleButtonGroup>
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<DeleteIcon />}
          disabled={!category}
          onClick={() => handleDelete()}
        >
          Delete
        </Button>
      </Grid>

    </Grid >
  )
})
