import { makeStyles, CssBaseline, AppBar, Toolbar, IconButton, Typography, Drawer, Divider, Box, Container, LinearProgress, Paper, Tab, Tabs, Backdrop, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import React, { useEffect } from 'react'
import { useAuth } from '../providers/AuthProvider';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Campaign } from '../models/RealmDataModels';
import CouponList from '../coupons/CouponList';
import { useParams, useHistory } from "react-router-dom";
import CreateMerchantForm from '../CreateMerchantForm';
import MenuForm from '../MenuForm';
import MerchantManagementTab from '../MerchantManagement/MerchantManagementTab';
import RealmUserForm from '../RealmUserForm';
import { BSON } from 'realm-web';
import CouponForm from '../coupons/CouponForm';
import HomeIcon from '@material-ui/icons/Home';



const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    borderBottomWidth: 2,
    borderBottom: "10px solid " + theme.palette.primary.main
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridGap: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
  },
  button: {
    alignSelf: 'center'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    maxHeight: "100%"
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

interface CouponsPageProps {

}

const CouponsPage = (props: CouponsPageProps) => {
  const classes = useStyles();
  const auth = useAuth();
  const history = useHistory<{ from?: string }>();


  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [coupons, setCoupons] = React.useState<Campaign[]>([]);
  const [selectedCoupon, setSelectedCoupon] = React.useState<Campaign>();
  const [loading, setLoading] = React.useState(false);


  const { id: campaignId } = useParams<{ id: string }>();


  useEffect(() => {
    if (auth.getUser()) {
      loadCoupons();
    }
  }, [auth.getUser()]);

  useEffect(() => {
    if (campaignId) {
      loadCoupon(campaignId)
    } else {
      setSelectedCoupon(undefined);
    }
  }, [campaignId]);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const loadCoupons = async () => {
    const user = auth.getUser();
    if (!user) {
      console.log("No user");
      return;
    };
    const mongodb = user.mongoClient("mongodb-atlas");
    const campaignColl = mongodb.db("jymba").collection<Campaign>("Campaign");
    const dbCampaign = await campaignColl.find({}, { projection: { _id: 1, campaignCode: 1, campaignStatus: 1, campaignType: 1, campaignValue: 1, deliveryType: 1 }, sort: { campaignCode: 1 } });
    setCoupons(dbCampaign);
    await loadCoupon(campaignId);
  }

  const loadCoupon = async (code: string) => {
    const user = auth.getUser();
    if (!user) {
      console.log("No user");
      return;
    };

    setLoading(true);

    const mongodb = user.mongoClient("mongodb-atlas");
    const campaignColl = mongodb.db("jymba").collection<Campaign>("Campaign");
    const dbCampaign = await campaignColl.findOne({ _id: new BSON.ObjectId(campaignId) });
    setSelectedCoupon(dbCampaign || undefined);

    setLoading(false);
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        color="default"
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: drawerOpen,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, drawerOpen && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h5" color="textPrimary" noWrap>
            Jymba
            <Typography variant="body1" color="secondary" noWrap>
              {process.env.REACT_APP_ENVIRONMENT}
            </Typography>
          </Typography>
          <Box ml="auto" />
          <IconButton
            color="inherit"
            edge="start"
            onClick={() => {
              history.push("/choose");
            }}
          >
            <HomeIcon />
          </IconButton>
        </Toolbar>

      </AppBar>

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={drawerOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <Typography variant="button">Current coupons</Typography>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <div>
          <CouponList
            onUpdated={() => loadCoupons()}
            coupons={coupons}
            selectedCoupon={selectedCoupon}
          />
        </div>
      </Drawer>

      <main className={clsx(classes.content, {
        [classes.contentShift]: drawerOpen,
      })}>
        <div className={classes.drawerHeader} />
        <Container maxWidth="lg">
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h6">
              {selectedCoupon ? `Coupon ${selectedCoupon.campaignCode}` : "New Coupon"}
            </Typography>
            <Box marginY={3}>
              <Divider />
            </Box>
            <CouponForm coupon={selectedCoupon} onRefresh={loadCoupons} />

          </Paper>
        </Container>

      </main>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  )
}

export default CouponsPage
