import React, { useEffect, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { MerchantFormProps } from "./BasicsForm";
import Divider from "@material-ui/core/Divider";
import Slider from "@material-ui/core/Slider";
import { MapContainer, TileLayer, GeoJSON, Marker, Popup } from "react-leaflet";
import { Layer, LeafletMouseEvent } from "leaflet";
import { Feature, GeometryObject } from "geojson";
import { HAMBURG_GEOJSON } from "./assets";
import "./AddressForm.css";
import { Button, ButtonGroup, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@material-ui/core";
import { useAuth } from "./providers/AuthProvider";

interface AddresMerchantFormProps extends MerchantFormProps {
  onAveragePreparationTimeChange: (value: number) => void;
  onMinOrderAmountChange: (value: number) => void;
  onDeliveryCostChange: (value: number) => void;
  onPostalCodesChange: (value: string[]) => void;
  onLocationChange: (value: number[]) => void;
}
const AddressForm: React.FC<AddresMerchantFormProps> = (props) => {
  const {
    handleChange,
    merchant,
    onDeliveryCostChange,
    onAveragePreparationTimeChange,
    onPostalCodesChange,
    onLocationChange,
    onMinOrderAmountChange,
  } = props;
  const { user } = useAuth();

  const onEachFeature = (
    feature: Feature<GeometryObject, any>,
    layer: Layer
  ) => {
    feature.id = feature.properties.name;
    layer.bindTooltip(` <h5>${feature.properties.name}</h5> `, {
      permanent: true,
      className: "my-labels",
    });
    layer.on({
      // mouseover: this.highlightFeature.bind(this),
      // mouseout: this.resetHighlight.bind(this),
      click: clickToFeature,
    });
  };

  function style(feature?: Feature<GeometryObject, any>) {
    if (!feature || !merchant) return { fillColor: "red" };

    const codes = merchant.deliveryArea.map((code) => {
      return code + "";
    });

    if (codes.includes(feature.properties.name)) {
      return { fillColor: "red" };
    }
    // if (feature.properties.state == hovered_state) return { 'fill-color': 'blue' }
    return { fillColor: "white" };
  }

  const _handleFindLoactionClick = async () => {
    const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;

    const addressEncoded = encodeURI(
      `${merchant.address.street}, ${merchant.address.postalCode} ${merchant.address.city}`
    );

    const googleGeoUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${addressEncoded}&key=${googleApiKey}`;
    const response = await fetch(googleGeoUrl);
    if (response.status === 200) {
      const responseBody = await response.json();

      if (responseBody.status === "OK") {
        const { lat, lng } = responseBody.results[0].geometry.location;

        onLocationChange([lat, lng]);
      }
    }
  };

  const clickToFeature = (e: LeafletMouseEvent) => {
    var layer = e.target;

    const code = layer.feature.properties.name;
    const idx = merchant.deliveryArea.indexOf(code);

    if (idx > -1) {
      //already in list ->remove it
      const updatedCodes = [...merchant.deliveryArea];
      updatedCodes.splice(idx, 1);

      onPostalCodesChange(updatedCodes);
    } else {
      onPostalCodesChange([...merchant.deliveryArea, code]);
    }
  };

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Restaurant Address
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            required
            id="address.street"
            name="address.street"
            label="Street + Nr."
            fullWidth
            focused={merchant.address?.street !== ""}
            value={merchant.address?.street}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="address.city"
            name="address.city"
            label="City"
            fullWidth
            focused={merchant.address?.city !== ""}
            value={merchant.address?.city}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="address.state"
            name="address.state"
            label="State/Province/Region"
            fullWidth
            focused={merchant.address?.state !== ""}
            value={merchant.address?.state}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="address.postalCode"
            name="address.postalCode"
            label="Zip / Postal code"
            fullWidth
            focused={merchant.address?.postalCode !== ""}
            value={merchant.address?.postalCode}
            onChange={handleChange}
            autoComplete="shipping postal-code"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Button variant="outlined" onClick={() => _handleFindLoactionClick()}>
            Find Location
          </Button>
          {console.log(
            "merchant.address.location: ",
            merchant.address.location
          )}
          {merchant.address.location &&
            merchant.address.location?.coordinates.length === 2 && (
              <Typography variant="body1">
                Location: [{merchant.address.location.coordinates[0]},{" "}
                {merchant.address.location.coordinates[1]}]
              </Typography>
            )}
        </Grid>

        <Grid item xs={12}>
          <Divider variant="middle" />
          <Typography variant="h6" gutterBottom>
            Delivery
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Delivery Basis</FormLabel>
            <RadioGroup
              name="deliveryBasis"
              id="deliveryBasis"
              value={merchant.deliveryBasis}
              onChange={handleChange}
            >
              <FormControlLabel value="zip" control={<Radio />} label="Zip" />
              <FormControlLabel
                value="radius"
                control={<Radio />}
                label="Radius"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography id="discrete-slider-always" gutterBottom>
            Delivery Cost (€)
          </Typography>
          <Slider
            id="deliveryCost"
            name="deliveryCost"
            step={0.5}
            min={0}
            max={10}
            valueLabelDisplay="on"
            value={merchant.deliveryCost as unknown as number}
            onChange={(e, val) => onDeliveryCostChange(val as number)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography id="discrete-slider-always" gutterBottom>
            Min. Order Amount (€)
          </Typography>
          <Slider
            id="minOrder"
            name="minOrder"
            step={1}
            min={0}
            max={50}
            valueLabelDisplay="on"
            value={merchant.minOrder}
            onChange={(e, val) => onMinOrderAmountChange(val as number)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography id="averagePreparationTime-slider-title" gutterBottom>
            Average Preparation Time
          </Typography>
          <Slider
            defaultValue={10}
            id="averagePreparationTime"
            name="averagePreparationTime"
            step={5}
            min={5}
            max={50}
            valueLabelDisplay="on"
            value={merchant.averagePreparationTime}
            onChange={(e, val) => onAveragePreparationTimeChange(val as number)}
          />
        </Grid>
        <Grid item xs={12}>
          <div style={{ width: "100%", height: 500 }}>
            <MapContainer
              style={{ width: "100%", height: 500 }}
              center={[53.5631388233983, 10.004700655334323]}
              zoom={11}
              scrollWheelZoom={true}
            >
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <GeoJSON
                key={merchant.deliveryArea.length + "_geo"}
                data={HAMBURG_GEOJSON}
                onEachFeature={onEachFeature}
                style={style}
              />
              {merchant &&
                merchant.address.location &&
                merchant.address.location.coordinates.length === 2 && (
                  <Marker
                    position={{
                      lat: merchant.address.location.coordinates[0],
                      lng: merchant.address.location.coordinates[1],
                    }}
                  >
                    <Popup>Test</Popup>
                  </Marker>
                )}
            </MapContainer>
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AddressForm;
