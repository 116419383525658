import { Grid, Paper, IconButton, makeStyles, Box, Avatar } from '@material-ui/core';
import * as React from 'react';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';


interface ImageUploadProps {
  handleDeleteImageClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, idx: number) => void;
  handleUploadClick: (event: React.ChangeEvent<HTMLInputElement>, idx: number) => Promise<void>;
  idx: number;
  img?: string;
  id: string;
  round?: boolean;
  className?: string;
}

export const ImageUpload: React.FC<ImageUploadProps> = (props) => {
  const classes = useStyles(props)();
  const { id, idx, handleUploadClick, handleDeleteImageClick, img } = props;

  if (props.round) {
    return (
      <div className={props.className}>
        <input
          accept="image/*"
          className={classes.input}
          id={`${id}-${idx}`}
          type="file"
          onChange={(e) => {
            handleUploadClick(e, idx);
          }}
        />
        <Box display="flex" position="relative">
          <label htmlFor={`${id}-${idx}`}>
            <Avatar src={img} className={classes.avatar} >
              <div>
                <IconButton color="primary" aria-label="upload picture" component="span">
                  <AddAPhotoIcon fontSize="large" color="primary" />
                </IconButton>
              </div>
            </Avatar>
            {img && (
              <Box display="flex" position="absolute" top={-3} right={-3} >
                <IconButton aria-label="delete" onClick={(e) => handleDeleteImageClick(e, idx)}>
                  <DeleteForeverIcon fontSize="large" color="secondary" />
                </IconButton>
              </Box>
            )}
          </label>
        </Box>
      </div>
    );
  }

  return (
    <Paper variant="outlined" className={classes.imageContainer}>
      <input
        accept="image/*"
        className={classes.input}
        id={`${id}-${idx}`}
        type="file"
        onChange={(e) => {
          handleUploadClick(e, idx);
        }}
      />
      <Box display="flex" position="relative">
        <label htmlFor={`${id}-${idx}`}>
          {img
            ? <img src={img} alt="" className={classes.image} />
            : (
              <div>
                <IconButton color="primary" aria-label="upload picture" component="span">
                  <AddAPhotoIcon fontSize="large" color="primary" />
                </IconButton>
              </div>
            )}
        </label>
        {img && (
          <Box display="flex" position="absolute" top={2} left={2} >
            <IconButton aria-label="delete" onClick={(e) => handleDeleteImageClick(e, idx)}>
              <DeleteForeverIcon fontSize="large" color="secondary" />
            </IconButton>
          </Box>
        )}
      </Box>
    </Paper>
  );
};


const useStyles = (props: ImageUploadProps) => makeStyles((theme) => ({
  input: {
    display: 'none',
  },
  imageContainer: {
    borderRadius: props.round ? 128 : 0,
    display: 'flex',
    flex: 1,
    height: '100%',
    width: '100%',
  },
  image: {
    borderRadius: props.round ? 128 : 0,
    flex: 1,
    height: '100%',
    width: '100%',
  },
  avatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    boxShadow: "rgb(171 153 145 / 24%) 0px 0px 2px 0px, rgb(171 158 145 / 24%) 0px 4px 8px -4px",
  }
}));