import { CircularProgress, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react'
import { Merchant, Staff } from './models/RealmDataModels';
import { useAuth } from './providers/AuthProvider';

interface StaffsAutocompleteFieldProps {
  onSelect: (staff: Staff | null) => void;
}

export const StaffsAutocompleteField = (props: StaffsAutocompleteFieldProps) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<Array<Staff & { merchantName?: string }>>([]);
  const auth = useAuth();

  const loading = open && options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      if (!auth.user) return;
      const mongodb = auth.user.mongoClient("mongodb-atlas");
      const staffCollection = mongodb.db("jymba").collection<Staff>("Staff");
      const merchantCollection = mongodb.db("jymba").collection<Merchant>("Merchant");
      const dbStaffs = await staffCollection.find();

      const staffsAndMerchant = await Promise.all(dbStaffs.map(async (staff) => {
        if (staff.merchantId) {
          const result = await merchantCollection.findOne({ _id: staff.merchantId }, { projection: { name: 1 } });
          console.log('result: ', result);
          if(result) {
            return {
              ...staff,
              merchantName: result.name
            }
          }
        }
        return staff;
      }));
      

      if (active) {
        setOptions(staffsAndMerchant);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, auth.user]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      fullWidth
      id="staffs"
      style={{ width: 600 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(event, value) => props.onSelect(value)}
      getOptionSelected={(option, value) => option._id === value._id}
      getOptionLabel={(option) => `${option.merchantName} - ${option.email} (${!option.name ? 'NEW' : option.name})`}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label="User Accounts"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
