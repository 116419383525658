import { AppBar, Box, Card, CardActionArea, CardContent, CardMedia, Container, CssBaseline, Grid, IconButton, Paper, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import OrdersGrid from '../MerchantManagement/OrdersGrid';
import { Merchant, Order, OrderWithMerchName } from '../models/RealmDataModels';
import { useAuth } from '../providers/AuthProvider';

interface ChoosePageProps {

}


const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardDisabled: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: 'gray'
  },
  paper: {
    marginBottom: '50px',
    display: 'flex',
    padding: '2px',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

const ChoosePage = (props: ChoosePageProps) => {
  const classes = useStyles();
  const auth = useAuth();

  const [ordersOfMonth, setOrdersOfMonth] = React.useState<Array<OrderWithMerchName>>([]);
  const [loadingOrders, setLoadingOrders] = React.useState(false);

  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();

  React.useEffect(() => {
    getOrdersOfMonth();
  }, [])


  const getOrdersOfMonth = async () => {
    setLoadingOrders(true);
    const user = auth.getUser();
    if (user) {
      try {
        const mongodb = user.mongoClient("mongodb-atlas");
        const ordersColl = mongodb.db("jymba").collection<Order>("Order");
        const merchantsColl = mongodb.db("jymba").collection<Merchant>("Merchant");

        const fromDate = new Date(currentYear, currentMonth, 1);
        const toDate = new Date(currentYear, currentMonth + 1, 1);

        // console.log("query:", { merchantId: merchant._id, _created: { $gte: fromDate.toISOString(), $lt: toDate.toISOString() } });

        const orders = await ordersColl.find({ _createdOn: { $gte: fromDate, $lt: toDate } });
        const merchants = await merchantsColl.find({}, { projection: { _id: 1, name: 1 } });
        // console.table(orders);
        const orderWithName = orders.map((order) => {
          const id = order.merchantId.toHexString();
          const merch = merchants.find((m) => m._id.toHexString() === id);

          return {
            ...order,
            merchantName: merch?.name || "Unknown"
          }
        })

        setOrdersOfMonth(orderWithName);
      } catch (e) {
        console.error(e);
      } finally {
        setLoadingOrders(false);
      }
    }
  }
  const totalRevenue = ordersOfMonth.map((order) => order.totalPrice).reduce((pre, curr) => pre + curr, 0);


  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="relative">
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            Jymba
          </Typography>
        </Toolbar>
      </AppBar>
      <main>
        <Container className={classes.cardGrid} maxWidth="md">
          <Grid container spacing={4}>

            <Grid item xs={12} sm={4} md={4}>
              <Card className={classes.card} elevation={8}>
                <CardActionArea component={RouterLink} to={'/merchant'}>
                  <CardMedia
                    className={classes.cardMedia}
                    image="https://source.unsplash.com/random/?restaurant"
                    title=""
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      Merchants
                    </Typography>
                    <Typography>
                      Edit and Create Merchants
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <Card className={classes.card} elevation={8}>
                <CardActionArea component={RouterLink} to={'/coupons'}>
                  <CardMedia
                    className={classes.cardMedia}
                    image="https://source.unsplash.com/random/?coupon"
                    title="Image title"
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      Coupons
                    </Typography>
                    <Typography>
                      Manage Coupons
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <Card className={classes.card} elevation={8}>
                <CardActionArea component={RouterLink} to={'/reviews'}>
                  <CardMedia
                    className={classes.cardMedia}
                    image="https://source.unsplash.com/random/?review"
                    title="Reviews"
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      Reviews (TODO)
                    </Typography>
                    <Typography>
                      Approve Reviews
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>

          </Grid>
        </Container>

        <Container maxWidth="lg">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h4">All Orders Of {monthNames[currentMonth]}</Typography>
              <Box my={2} />
              <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Typography variant="h6">Total Revenue {totalRevenue.toFixed(2).replace('.', ',')} </Typography>
                <Typography variant="h6">Total Orders {ordersOfMonth.length} </Typography>
                <IconButton onClick={() => getOrdersOfMonth()}>
                  <AutorenewIcon />
                </IconButton>
              </Box>
              <Box my={1} />
              <Paper variant="outlined" className={classes.paper}>
                <OrdersGrid orders={ordersOfMonth} loading={loadingOrders} showMerchantName />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </main>
    </React.Fragment>
  )
}

export default ChoosePage
