import React from 'react';
import { Redirect, Route, RouteProps, useHistory } from 'react-router-dom';
import { useAuth } from '../providers/AuthProvider';

export interface PrivateRouteProps extends RouteProps {
  children: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = (props) => {
  const { children , ...rest } = props;
  const auth = useAuth();
  const history = useHistory();

  console.log("auth.isAuthenticated ", auth.isAuthenticated )
  console.log("redirect", history.location.pathname);

  return (
    <Route {...rest} render={() => {
      return auth.isAuthenticated ? children : <Redirect to={{
        pathname:"/login",
        state:{
          from: history.location.pathname
        }
      }} />
    }}>
      
    </Route>
  )
};


export default PrivateRoute;
