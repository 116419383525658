import { List, ListItem, ListItemIcon, ListItemText, Divider, Switch, Typography } from '@material-ui/core';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import React from 'react';
import { BSON } from 'realm-web';
import { Link } from 'react-router-dom';
import { Campaign, Merchant } from '../models/RealmDataModels';
import { useAuth } from '../providers/AuthProvider';

interface CouponListProps {
  coupons: Array<Campaign>;
  selectedCoupon?: Campaign;
  onUpdated: () => void;
}

const CouponList = (props: CouponListProps) => {
  const { coupons, selectedCoupon, onUpdated } = props;
  const auth = useAuth();

  const _handleActiveChange = async (id: BSON.ObjectId, active: boolean) => {
    const user = auth.getUser();
    if (user) {
      const mongodb = user.mongoClient("mongodb-atlas");
      const campaignColl = mongodb.db("jymba").collection<Campaign>("Campaign");
      await campaignColl.updateOne({ _id: id }, {
        $set: { campaignStatus: active ? 'active' : 'inactive' }
      });
      onUpdated();
    }
  }

  return (
    <List dense>
      <ListItem
        button
        selected={selectedCoupon === undefined}
        component={Link}
        to="/coupons">
        <ListItemIcon>
          <AddCircleOutlineOutlinedIcon color="primary" />
        </ListItemIcon>
        <ListItemText color="primary" primary="Create New Coupon" />
      </ListItem>
      <Divider />

      {coupons.map((coupon, idx) => (
        <React.Fragment key={coupon._id.toHexString()}>
          <ListItem
            key={coupon.campaignCode}
            button
            selected={selectedCoupon?._id.toHexString() === coupon._id.toHexString()}
            component={Link}
            to={`/coupons/${coupon._id.toHexString()}`}
          >
            <ListItemText
              primary={<Typography variant='body1'><strong>{coupon.campaignCode}</strong> ({coupon.deliveryType})</Typography>}
              secondary={(
                <>
                  <Typography variant='body2'>{coupon._id.toHexString()}</Typography>
                </>
              )}
            />

            <Switch
              size="small"
              checked={coupon.campaignStatus === 'active'}
              onChange={(e, checked) => _handleActiveChange(coupon._id, checked)}
              color="secondary"
              name="active"
            />
            {/* <ListItemIcon onClick={() => _handleDownload(merch)}>
            </ListItemIcon> */}
          </ListItem>
          <Divider />
        </React.Fragment>
      ))}
    </List>
  )
}

export default CouponList
