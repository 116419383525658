import React, { useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { Redirect, useHistory } from 'react-router-dom';
import { useAuth } from '../providers/AuthProvider';


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const LogInPage: React.FC = () => {
  const classes = useStyles();

  const auth = useAuth();
  const history = useHistory<{from?: string}>();


  const [redirect, setRedirect] = useState(auth.isAuthenticated);
  const [email, setEmail] = useState<string>('');
  const [pwd, setPwd] = useState<string>('');
  const [loginError, setLoginError] = useState<string>();
  const [loading, setIsLoading] = useState(false);

  useEffect(() => {
    if (auth.isAuthenticated) {
      setRedirect(true);
    }
  }, [auth.isAuthenticated]);
  
  const logIn = async () => {
    if(email && pwd) {
      setIsLoading(true);
      try {
        const success = await auth.login(email, pwd);
        if(!success) {
          setLoginError('Not an admin user');
        }
      } catch(e) {
        setLoginError(e.message);
      }
      setLoginError(undefined);
      setIsLoading(false);
    }
  }

  if (redirect) {
    if(history.location.state.from) {
      return <Redirect to={history.location.state.from} />
    } else {
      return <Redirect to='/choose' />
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Paper elevation={3} className={classes.paper}>
        <Avatar className={classes.avatar}>
          {/* <LockOutlinedIcon /> */}
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            error={loginError !== undefined}
            helperText={loginError && 'Email or password wrong'}
            value={pwd}
            onChange={(event) => setPwd(event.target.value)}
            autoComplete="current-password"
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={logIn}>
            {loading ? "..." : "Sign In"} 
          </Button>
        </form>
      </Paper>
    </Container>
  );
}

export default LogInPage;

