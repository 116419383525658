import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
  ItemConfigGroup,
  ItemConfigOption,
  Merchant,
} from "./models/RealmDataModels";
import { useAuth } from "./providers/AuthProvider";
import { makeStyles } from "@material-ui/core/styles";
import { Button, IconButton, Paper } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { BSON } from "realm-web";
import Avatar from "@material-ui/core/Avatar";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import Box from "@material-ui/core/Box";
import ListSubheader from "@material-ui/core/ListSubheader";
import Slider from "@material-ui/core/Slider";
import arrayMove from "array-move";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import DeleteIcon from "@material-ui/icons/Delete";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import EditIcon from "@material-ui/icons/Edit";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export type ItemConfigGroupFormInput = Omit<ItemConfigGroup, "_id">;
export type SnackbarInfo = {
  text: string;
  success: boolean;
};

const defaultItemConfigGroup: ItemConfigGroupFormInput = {
  _partition: "",
  merchantId: new BSON.ObjectID(),
  maxSelection: 1,
  optionsCount: 0,
  title: "",
  desc: "",
  options: [],
  // Default Values
  _ver: "1.0",
  _createdBy: "1",
  _updatedBy: "1",
  _createdOn: new Date(),
  _updatedOn: new Date(),
  _status: "active",
};

const defaultOption: ItemConfigOption = {
  title: "",
  desc: "",
  price: 0,
  rank: 0,
};

interface OptionGroupFormmProps {
  itemConfigGroup?: ItemConfigGroup;
  onAddedGroup: () => void;
  merchant: Merchant;
}

const OptionGroupForm: React.FC<OptionGroupFormmProps> = (props) => {
  const { itemConfigGroup, onAddedGroup, merchant } = props;
  const auth = useAuth();
  const classes = useStyles();
  const [optionGroupFormVals, setOptionGroupFormVals] =
    useState<ItemConfigGroupFormInput>(defaultItemConfigGroup);
  const [optionFormVals, setOptionFormVals] =
    useState<ItemConfigOption>(defaultOption);
  const [addOptionMode, setAddOptionMode] = useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState<SnackbarInfo>();

  useEffect(() => {
    if (itemConfigGroup) {
      setOptionGroupFormVals(itemConfigGroup);
    } else {
      setOptionGroupFormVals(defaultItemConfigGroup);
    }
  }, [itemConfigGroup]);


  const onRemoveOption = (option: ItemConfigOption) => {
    const arr = [...optionGroupFormVals.options];
    const index = arr.indexOf(option);
    if (index > -1) {
      arr.splice(index, 1);
    }

    setOptionGroupFormVals({
      ...optionGroupFormVals,
      options: arr,
    });
  };

  const onSaveGroup = async () => {
    if (!auth.user) return;

    // Get mongo client
    const mongodb = auth.user.mongoClient("mongodb-atlas");
    const itemConfigGroupCollection = mongodb
      .db("jymba")
      .collection<ItemConfigGroup>("ItemConfigGroup");
    try {
      if (itemConfigGroup) {
        // Update

        await itemConfigGroupCollection.updateOne(
          {
            _id: itemConfigGroup._id,
          },
          {
            ...optionGroupFormVals,
            optionsCount: optionGroupFormVals.options.length,
            _updatedBy: "1",
            _updatedOn: new Date(),
            options: optionGroupFormVals.options
              .sort((a, b) => a.rank - b.rank)
              .map((op, idx) => {
                return {
                  ...op,
                  price: new BSON.Double(op.price as number),
                  rank: idx,
                };
              }),
          }
        );
        // Success
        setOpenSnackbar({ text: "Updated Option Group", success: true });
        setAddOptionMode(false);
        setOptionFormVals({
          ...defaultOption,
          rank: optionGroupFormVals.options.length,
        });
        onAddedGroup();
      } else {
        // create new
        const result = await itemConfigGroupCollection.insertOne({
          ...optionGroupFormVals,
          _id: new BSON.ObjectID(),
          merchantId: merchant._id,
          _partition: "merchant=" + merchant._id,

          optionsCount: optionGroupFormVals.options.length,
          options: optionGroupFormVals.options.map((op, idx) => {
            return {
              ...op,
              price: new BSON.Double(op.price as number),
              rank: idx,
            };
          }),
        });

        // Success
        setAddOptionMode(false);
        setOptionFormVals({
          ...defaultOption,
          rank: optionGroupFormVals.options.length,
        });
        onAddedGroup();
        setOpenSnackbar({
          text: `Inserted Option Group (${result.insertedId})`,
          success: true,
        });
      }
    } catch (e) {
      if (e instanceof Error) {
        setOpenSnackbar({ text: e.message, success: false });
      } else {
        setOpenSnackbar({ text: "Error", success: false });
      }
    }
  };

  const onOptionAdd = () => {
    setAddOptionMode(false);
    const arr = [...optionGroupFormVals.options];
    arr.push({
      ...optionFormVals,
    });

    setOptionGroupFormVals({
      ...optionGroupFormVals,
      options: arr,
    });
  };

  const onOptionCancle = () => {
    setAddOptionMode(false);
    setOptionFormVals({
      ...defaultOption,
      rank: optionGroupFormVals.options.length,
    });
  };

  const renderNewOptionForm = () => {
    if (!addOptionMode) return null;
    return (
      <Box border={1} borderRadius="2%" className={classes.newOptionContainer}>
        <Grid container spacing={1}>
          <Grid container spacing={1}>
            {/* OPTION TITLE */}
            <Grid item xs={10} sm={10}>
              <TextField
                autoFocus
                fullWidth
                margin="dense"
                id="option-title"
                name="option-title"
                label="Title"
                onChange={(e) =>
                  setOptionFormVals({
                    ...optionFormVals,
                    title: e.target.value,
                  })
                }
              />
            </Grid>
            {/* OPTION PRICE */}
            <Grid item xs={2} sm={2}>
              <TextField
                margin="dense"
                id="option-price"
                type="number"
                name="option-price"
                label="Price (€)"
                value={optionFormVals.price}
                onChange={(e) =>
                  setOptionFormVals({
                    ...optionFormVals,
                    price: Number(e.target.value),
                  })
                }
              />
            </Grid>
          </Grid>
          {/* OPTION DESCRIPTION */}
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              multiline
              margin="dense"
              id="option-desc"
              name="option-desc"
              label="Description"
              onChange={(e) =>
                setOptionFormVals({ ...optionFormVals, desc: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              margin="dense"
              id="option-rank"
              type="number"
              name="option-rank"
              label="Rank"
              value={optionFormVals.rank}
              onChange={(e) =>
                setOptionFormVals({
                  ...optionFormVals,
                  rank: Number(e.target.value),
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} alignContent="space-between">
            <Grid container xs={12} sm={12} alignContent="space-between">
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onOptionAdd}
                >
                  Add
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={onOptionCancle}
                >
                  Cancle
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const sortedOptions = optionGroupFormVals.options.sort(
    (a, b) => a.rank - b.rank
  );

  return (
    <React.Fragment>
      <Grid item xs={12} sm={12}>
        <Paper
          variant="outlined"
          square
          className={classes.optionGroupsEditContainer}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <Typography variant="subtitle1">
                {itemConfigGroup ? "Edit Config Group" : "Create Config Group"}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Button variant="outlined" color="primary" onClick={onSaveGroup}>
                {itemConfigGroup ? "Save Group" : "Add Group"}
              </Button>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => {
                  setOptionGroupFormVals(defaultItemConfigGroup);
                  setAddOptionMode(false);
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            {/* NAME */}
            <Grid item xs={12} sm={12}>
              <TextField
                autoFocus
                margin="dense"
                id="title"
                required
                name="title"
                label="Title"
                focused={optionGroupFormVals.title !== ""}
                value={optionGroupFormVals.title}
                onChange={(e) =>
                  setOptionGroupFormVals({
                    ...optionGroupFormVals,
                    title: e.target.value,
                  })
                }
                fullWidth
              />
            </Grid>

            {/* DESC */}
            <Grid item xs={12} sm={12}>
              <TextField
                autoFocus
                fullWidth
                multiline
                margin="dense"
                id="desc"
                name="desc"
                label="Description"
                focused={optionGroupFormVals.desc !== ""}
                value={optionGroupFormVals.desc}
                onChange={(e) =>
                  setOptionGroupFormVals({
                    ...optionGroupFormVals,
                    desc: e.target.value,
                  })
                }
              />
            </Grid>
            {/* Max Selection */}
            <Grid item xs={12} sm={12}>
              <Typography id="discrete-slider-always" gutterBottom>
                Max Selection
              </Typography>
              <Slider
                defaultValue={1}
                id="maxSelection"
                name="maxSelection"
                step={1}
                min={1}
                max={optionGroupFormVals.options.length}
                valueLabelDisplay="on"
                value={optionGroupFormVals.maxSelection}
                onChange={(e, value) =>
                  setOptionGroupFormVals({
                    ...optionGroupFormVals,
                    maxSelection: Number(value),
                  })
                }
              />
            </Grid>

            {/* OPTIONS */}
            <Grid item xs={12} sm={12}>
              <List
                dense
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    Options
                  </ListSubheader>
                }
              >
                <ListItem
                  button
                  onClick={() => {
                    setAddOptionMode(true);
                  }}
                >
                  <ListItemIcon>
                    <AddCircleOutlineOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText color="primary" primary={"Add"} />
                </ListItem>
                <ListItem>{renderNewOptionForm()}</ListItem>
                {sortedOptions.map((option) => (
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>{option.price} €</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={option.title + " - Rank: " + option.rank}
                      secondary={option.desc}
                    />
                    <ListItemSecondaryAction>
                      <IconButton onClick={() => onRemoveOption(option)}>
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Snackbar
        open={openSnackbar !== undefined}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(undefined)}
      >
        <Alert
          onClose={() => setOpenSnackbar(undefined)}
          severity={openSnackbar?.success ? "success" : "error"}
        >
          {openSnackbar?.text}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default OptionGroupForm;

const useStyles = makeStyles((theme) => ({
  newOptionContainer: {
    padding: 10,
    borderColor: "lightgray",
    borderWidth: 1,
  },
  optionGroupsEditContainer: {
    padding: 10,
  },
}));
