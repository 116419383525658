import { Box, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import React from 'react';
import { Merchant, Order } from '../models/RealmDataModels';
import { useAuth } from '../providers/AuthProvider';
import OrdersGrid from './OrdersGrid';

interface MerchantManagementTabProps {
  merchant: Merchant;
}

const MerchantManagementTab = (props: MerchantManagementTabProps) => {

  const { merchant } = props;

  const auth = useAuth();

  const [month, setMonth] = React.useState(new Date().getMonth());
  const [year, setYear] = React.useState(2023);
  const [ordersOfMonth, setOrdersOfMonth] = React.useState<Array<Order>>([]);
  const [loadingOrders, setLoadingOrders] = React.useState(false);

  React.useEffect(() => {
    setMonth(new Date().getMonth());
  }, [])

  React.useEffect(() => {
    getOrdersOfMonth();
  }, [month])

  const handleMonthChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setMonth(event.target.value as number);
  };

  const handleYearChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setYear(event.target.value as number);
  };


  const getOrdersOfMonth = async () => {
    setLoadingOrders(true);
    const user = auth.getUser();
    if (user) {
      try {
        const mongodb = user.mongoClient("mongodb-atlas");
        const ordersColl = mongodb.db("jymba").collection<Order>("Order");

        const fromDate = new Date(year, month, 1);
        const toDate = new Date(year, month + 1, 1);

        // console.log("query:", { merchantId: merchant._id, _created: { $gte: fromDate.toISOString(), $lt: toDate.toISOString() } });

        const orders = await ordersColl.find({ merchantId: merchant._id, _createdOn: { $gte: fromDate, $lt: toDate } });
        console.log('orders:', orders.length);
        // console.table(orders);

        setOrdersOfMonth(orders);
      } catch (e) {
        console.error(e);
      } finally {
        setLoadingOrders(false);
      }
    }
  }
  const totalRevenue = ordersOfMonth.map((order) => order.totalPrice).reduce((pre, curr) => pre + curr, 0);

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Manage the merchant
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={3} sm={3}>
          <FormControl variant="outlined">
            <InputLabel>Year</InputLabel>
            <Select
              value={year}
              onChange={handleYearChange}
              label="Year"
            >
              <MenuItem value={2021}>2021</MenuItem>
              <MenuItem value={2022}>2022</MenuItem>
              <MenuItem value={2023}>2023</MenuItem>
              <MenuItem value={2024}>2024</MenuItem>            
            </Select>
          </FormControl>
          <Box my={2} />
        </Grid>

        <Grid item xs={3} sm={3}>
          <FormControl variant="outlined">
            <InputLabel>Month</InputLabel>
            <Select
              value={month}
              onChange={handleMonthChange}
              label="Month"
            >
              <MenuItem value={0}>January</MenuItem>
              <MenuItem value={1}>Feburary</MenuItem>
              <MenuItem value={2}>March</MenuItem>
              <MenuItem value={3}>April</MenuItem>
              <MenuItem value={4}>May</MenuItem>
              <MenuItem value={5}>June</MenuItem>
              <MenuItem value={6}>July</MenuItem>
              <MenuItem value={7}>August</MenuItem>
              <MenuItem value={8}>September</MenuItem>
              <MenuItem value={9}>October</MenuItem>
              <MenuItem value={10}>November</MenuItem>
              <MenuItem value={11}>Dezember</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Divider />
          <Box my={2} />
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Typography variant="h6">Total Revenue {totalRevenue.toFixed(2).replace('.', ',')} </Typography>
            <Typography variant="h6">Orders {ordersOfMonth.length} </Typography>
            <IconButton aria-label="refresh" onClick={() => getOrdersOfMonth()}>
              <AutorenewIcon />
            </IconButton>
          </Box>
          <Box my={1} />
          
          <OrdersGrid orders={ordersOfMonth} loading={loadingOrders} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default MerchantManagementTab
