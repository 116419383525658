import React, { useEffect, useState } from "react";
import "./CreateMerchantForm.css";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import AddressForm from "./AddressForm";
import BankForm from "./BankForm";
import BasicsForm from "./BasicsForm";
import RealmUserForm, { NewUserFormData } from "./RealmUserForm";
import { useAuth } from "./providers/AuthProvider";
import {
  Merchant,
  Staff,
  MerchantWeekdayTiming,
  Subscription,
  MerchantSpectialTiming,
} from "./models/RealmDataModels";
import _ from "lodash";
import { nanoid } from "nanoid";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { BSON } from "realm-web";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { SnackbarInfo } from "./OptionGroupForm";
import slugify from "slugify";

const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

export type MerchantFormInput = Omit<Merchant, "_id">;

const defaultMerchant: MerchantFormInput = {
  name: "",
  slug: "",
  website: "",
  phone: "",
  desc: "",
  imprint: "",
  deliveryBasis: "zip",
  services: [],
  items: [],
  timings: [
    {
      weekday: "mon",
      openingHours: [
        {
          from: 630,
          to: 1320,
        },
      ],
    },
    {
      weekday: "tue",
      openingHours: [
        {
          from: 630,
          to: 1320,
        },
      ],
    },
    {
      weekday: "wed",
      openingHours: [
        {
          from: 630,
          to: 1320,
        },
      ],
    },
    {
      weekday: "thu",
      openingHours: [
        {
          from: 630,
          to: 1320,
        },
      ],
    },
    {
      weekday: "fri",
      openingHours: [
        {
          from: 630,
          to: 1320,
        },
      ],
    },
    {
      weekday: "sat",
      openingHours: [
        {
          from: 630,
          to: 1320,
        },
      ],
    },
    {
      weekday: "sun",
      openingHours: [
        {
          from: 630,
          to: 1320,
        },
      ],
    },
  ],
  images: [],
  logoUrl: "",
  thumbnail: "",
  address: {
    city: "",
    location: {
      coordinates: [],
      type: "point",
    },
    postalCode: "",
    state: "",
    street: "",
  },
  deliveryCost: new BSON.Double(0),
  marginDistance: 100,
  averagePreparationTime: 10,
  paypalId: "",

  cuisines: [],
  cuisinesEn: [],
  deliveryArea: [],
  minOrder: 0,

  taxDetail: {
    company: "",
    taxId: "",
    taxNumber: "",
  },

  bankDetails: {
    accountHolder: "",
    bank: "",
    bic: "",
    branch: "",
    iban: "",
  },
  merchantCode: "",
  // DEFAULT VALUES
  _createdOn: new Date(),
  _updatedOn: new Date(),
  _status: "inactive",
  _createdBy: "1",
  _updatedBy: "1",
  _ver: "1.0",
  merchantStatus: "normal",
  rating: new BSON.Double(3),
  numReviews: new BSON.Double(0.0),
  settings: {
    notifications: {
      orders: {
        email: true,
        push: true,
      },
      reservations: {
        email: true,
        push: true,
      },
    },
  },
  _partition: "",
};

export interface CreateMerchantFormProps {
  merchantId?: BSON.ObjectId;
  onMerchantCreated: () => void;
}

const CreateMerchantForm: React.FC<CreateMerchantFormProps> = (props) => {
  const { merchantId, onMerchantCreated } = props;

  const auth = useAuth();
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState<string[]>([]);
  const [merchantValues, setMerchantValues] =
    useState<MerchantFormInput>(defaultMerchant);
  const [merchant, setMerchant] = useState<Merchant>();

  const [openSnackbar, setOpenSnackbar] = React.useState<SnackbarInfo>();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!merchantId) {
      setMerchant(undefined);
    } else {
      const loadMerchant = async (id: BSON.ObjectId) => {
        // setLoading(true);
        if (!auth.user) return;

        const mongodb = auth.user.mongoClient("mongodb-atlas");
        const merchants = mongodb.db("jymba").collection<Merchant>("Merchant");
        const dbMerchant = await merchants.findOne({ _id: id });
        if (dbMerchant) {
          setMerchant(dbMerchant);
        } else {
          setMerchant(undefined);
        }

        setLoading(false);
      };

      loadMerchant(merchantId);
    }
  }, [auth.user, merchantId]);

  useEffect(() => {
    if (merchant) {
      setMerchantValues({ ...merchant });
      setSteps(["Restaurant", "Address & Delivery"]);
    } else {
      setMerchantValues({ ...defaultMerchant });
      setSteps(["Restaurant", "Address & Delivery"]);
    }
    setActiveStep(0);
  }, [merchant]);

  const upload = async (
    img: string,
    idx: number,
    merchantCode: string,
    merchantName: string
  ) => {
    const storage = await auth.getFirebaseStorage();

    if (storage) {
      try {
        const fileName = `${merchantCode}_${idx}_${new Date().toISOString()}`;
        const reference = storage.ref(`/merchant/${merchantCode}/${fileName}`);
        await reference.putString(img as string, "data_url");
        const url = await reference.getDownloadURL();

        return url as string;
      } catch (e) {
        console.error(e);
        if (e instanceof Error) {
          throw new Error("Error on uploadImgToFirebase:" + e.message);
        } else {
          throw new Error("Error on uploadImgToFirebase");
        }
      }
    } else {
      console.error(img, merchant);
      throw new Error("Connection to firebase failed: no storage");
    }
  };

  const uploadImgsToFirebase = async (
    merchantCode: string,
    merchantName: string
  ) => {
    const urls = await Promise.all(
      merchantValues.images.map(async (img, idx) => {
        if (img.startsWith("https://") || img.startsWith("http://")) {
          return img;
        }
        // resize & upload
        return upload(img, idx, merchantCode, merchantName);
      })
    );

    return urls;
  };

  const _createSlug = (merchant: MerchantFormInput) => {
    return slugify(`${merchant.name} ${merchant.address.postalCode}`, {
      replacement: "-", // replace spaces with replacement character, defaults to `-`
      remove: undefined, // remove characters that match regex, defaults to `undefined`
      lower: true, // convert to lower case, defaults to `false`
      strict: true, // strip special characters except replacement, defaults to `false`
      locale: "de", // language code of the locale to use
      trim: true, // trim leading and trailing replacement chars, defaults to `true`
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    if (!auth.user) return;
    event.preventDefault();
    event.stopPropagation();
    setLoading(true);
    try {
      // Get mongo client
      const mongodb = auth.user.mongoClient("mongodb-atlas");
      const merchantsCollection = mongodb
        .db("jymba")
        .collection<Merchant>("Merchant");
      const subscriptionCollection = mongodb
        .db("jymba")
        .collection<Subscription>("Subscription");

      //* 1.1 get location
      const { address } = merchantValues;

      if (!address.location?.coordinates) {
        setOpenSnackbar({
          text: "No location for merchant. Please use 'Find Location Button' ",
          success: false,
        });
        return;
      }

      if (merchant) {
        // !Update Merchant
        //* 1a Upload Image to Firebase
        const images = await uploadImgsToFirebase(
          merchantValues.merchantCode,
          merchantValues.name
        );

        let logo = merchantValues.logoUrl;
        if (
          !logo.startsWith("https://") &&
          !logo.startsWith("http://") &&
          logo !== ""
        ) {
          logo =
            (await upload(
              logo,
              0,
              merchantValues.merchantCode,
              merchantValues.name
            )) || merchantValues.logoUrl;
        }

        console.log("merchantValues.timings", merchantValues.timings);
        await merchantsCollection.updateOne(
          {
            _id: merchant._id,
          },
          {
            ...merchantValues,
            _updatedOn: new Date(),
            _updatedBy: "1",
            _ver: "1.0",
            slug: _createSlug(merchantValues),
            rating:
              typeof merchantValues.rating === "number"
                ? new BSON.Double(merchantValues.rating as number)
                : merchantValues.rating,
            numReviews:
              typeof merchantValues.numReviews === "number"
                ? new BSON.Double(merchantValues.numReviews as number)
                : merchantValues.numReviews,
            deliveryCost:
              typeof merchantValues.deliveryCost === "number"
                ? new BSON.Double(merchantValues.deliveryCost as number)
                : merchantValues.deliveryCost,
            images: images,
            logoUrl: logo,
          }
        );
        setOpenSnackbar({ text: "Updated Merchant", success: true });
        setActiveStep(activeStep + 1);
      } else {
        // !Create Merchant
        //* 2. Create merchant Id & code
        const merchantId = new BSON.ObjectID();
        const merchantCode = _.toUpper(`M-${nanoid(6)}`);

        //* 1a Upload Image to Firebase
        const images = await uploadImgsToFirebase(
          merchantCode,
          merchantValues.name
        );

        let logo = merchantValues.logoUrl;
        if (
          !logo.startsWith("https://") &&
          !logo.startsWith("http://") &&
          logo !== ""
        ) {
          logo =
            (await upload(logo, 0, merchantCode, merchantValues.name)) ||
            merchantValues.logoUrl;
        }

        //* 4. Create new Merchant Document
        await merchantsCollection.insertOne({
          ...merchantValues,
          merchantCode: merchantCode,
          _id: merchantId,
          slug: _createSlug(merchantValues),
          _partition: "merchant=" + merchantId,
          images: images,
          logoUrl: logo,
          rating:
            typeof merchantValues.rating === "number"
              ? new BSON.Double(merchantValues.rating as number)
              : merchantValues.rating,
          numReviews:
            typeof merchantValues.numReviews === "number"
              ? new BSON.Double(merchantValues.numReviews as number)
              : merchantValues.numReviews,
          deliveryCost: merchantValues.deliveryCost,
        });

        //* 5. Create new Subscription Document
        await subscriptionCollection.insertOne({
          merchant: merchantId,
          year: new Date().getFullYear(),
          prices: [],
          _createdBy: "1",
          _updatedBy: "1",
          _createdOn: new Date(),
          _updatedOn: new Date(),
          _partition: `merchant=${merchantId}`,
          _status: "active",
          _ver: "1",
        });

        setOpenSnackbar({
          text: `Created Merchant (${merchantId.toHexString()})`,
          success: true,
        });
        setActiveStep(activeStep + 1);
        onMerchantCreated();
      }
    } catch (e) {
      console.log("Error", e);
      if (e instanceof Error) {
        setOpenSnackbar({ text: e.message, success: false });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCuisinesChange = (values: string[]) => {
    setMerchantValues({
      ...merchantValues,
      cuisines: values,
    });
  };

  const handleCuisinesEnChange = (values: string[]) => {
    setMerchantValues({
      ...merchantValues,
      cuisinesEn: values,
    });
  };

  const handleImagesChange = (imageFiles: string[]) => {
    setMerchantValues({
      ...merchantValues,
      images: imageFiles,
    });
  };

  const handleLogoChange = (imageFile: string) => {
    setMerchantValues({
      ...merchantValues,
      logoUrl: imageFile,
    });
  };

  const handleChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const { value, name } = event.target;
    // console.log('handleChange', value, name);

    if (name) {
      // console.log('handleChange', value, name);

      if (name.includes("from") || name.includes("to")) {
        var a = (value as string).split(":"); // split it at the colons

        // Hours are worth 60 minutes.
        var minutes = +a[0] * 60 + +a[1];
        _.set(merchantValues, name, minutes);
      } else {
        // use dot notation to set object
        _.set(merchantValues, name, value);
      }

      setMerchantValues({
        ...merchantValues,
      });
    }
  };

  const onDeliveryCostChange = (val: number) => {
    setMerchantValues({
      ...merchantValues,
      deliveryCost: new BSON.Double(val),
    });
  };

  const onPostalCodesChange = (val: string[]) => {
    setMerchantValues({ ...merchantValues, deliveryArea: [...val] });
  };

  const onLocationChange = (val: number[]) => {
    const updatedMerchant = { ...merchantValues };
    if (updatedMerchant.address.location) {
      updatedMerchant.address.location.coordinates = val;
      setMerchantValues(updatedMerchant);
    }
  };

  const onAveragePreparationTimeChange = (val: number) => {
    setMerchantValues({ ...merchantValues, averagePreparationTime: val });
  };

  const onMinOrderAmountChange = (val: number) => {
    setMerchantValues({ ...merchantValues, minOrder: val });
  };

  const handleTimingsChange = (timings: Array<MerchantWeekdayTiming>) => {
    setMerchantValues({ ...merchantValues, timings: timings });
  };

  const handleSpecialTimingsChange = (
    specialTimings: Array<MerchantSpectialTiming>
  ) => {
    setMerchantValues({ ...merchantValues, specialTimings: specialTimings });
  };

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <BasicsForm
            merchant={merchantValues}
            handleChange={handleChange}
            handleCuisinesChange={handleCuisinesChange}
            handleCuisinesEnChange={handleCuisinesEnChange}
            handleImagesChange={handleImagesChange}
            handleLogoChange={handleLogoChange}
            handleTimingsChange={handleTimingsChange}
            handleSpecialTimingsChange={handleSpecialTimingsChange}
          />
        );
      case 1:
        return (
          <AddressForm
            merchant={merchantValues}
            onPostalCodesChange={onPostalCodesChange}
            handleChange={handleChange}
            onDeliveryCostChange={onDeliveryCostChange}
            onAveragePreparationTimeChange={onAveragePreparationTimeChange}
            onMinOrderAmountChange={onMinOrderAmountChange}
            onLocationChange={onLocationChange}
          />
        );

      // case 3:
      //   return <BankForm merchant={merchantValues} handleChange={handleChange} />;
      case 2:
        return <Paper>Something went wrong...</Paper>;
      default:
        throw new Error("Unknown step");
    }
  };

  const handleNext = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    event.preventDefault();
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Merchant
      </Typography>
      <Stepper activeStep={activeStep} className={classes.stepper}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <React.Fragment>
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography variant="h5" gutterBottom>
              {merchant ? "Merchant Updated" : "Merchant Created"}
            </Typography>
            {/* {!merchant && (
              <Typography variant="subtitle1">
                <p>UserId:  {newUserData.userId}</p>
                <p>Email: {newUserData.email}</p>
                <p>Password: {newUserData.password}</p>
              </Typography>
            )} */}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <form onSubmit={handleSubmit}>
              {getStepContent(activeStep)}

              <div className={classes.buttons}>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} className={classes.button}>
                    Back
                  </Button>
                )}
                {activeStep === steps.length - 1 ? (
                  <Button
                    variant="contained"
                    color="primary"
                    id="submit"
                    type={"submit"}
                    className={classes.button}
                  >
                    {merchant === undefined
                      ? "Add Merchant"
                      : "Update Merchant"}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    id="next"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    Next
                  </Button>
                )}
              </div>
            </form>
          </React.Fragment>
        )}
      </React.Fragment>
      <Snackbar
        open={openSnackbar !== undefined}
        autoHideDuration={10000}
        onClose={() => setOpenSnackbar(undefined)}
      >
        <Alert
          onClose={() => setOpenSnackbar(undefined)}
          severity={openSnackbar?.success ? "success" : "error"}
        >
          {openSnackbar?.text}
        </Alert>
      </Snackbar>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  );
};

export default CreateMerchantForm;

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 800,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
