import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useAuth } from './providers/AuthProvider';
import { Merchant, Staff } from './models/RealmDataModels';
import { StaffsAutocompleteField } from './StaffsAutocompleteField';
import { Backdrop, Box, Button, Card, CardContent, CardHeader, CircularProgress, Divider, IconButton, Snackbar, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SnackbarInfo } from './OptionGroupForm';
import { Alert } from '@material-ui/lab';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { StandaloneSearchBox } from '@react-google-maps/api';
import { BSON } from 'realm-web';

export interface NewUserFormData {
  password: string,
  email: string,
  userId: string
}

interface RealmUserFormProps {
  merchant: Merchant;
}

const RealmUserForm: React.FC<RealmUserFormProps> = (props) => {
  const classes = useStyles();
  const { merchant } = props;

  const [openSnackbar, setOpenSnackbar] = React.useState<SnackbarInfo>();

  const auth = useAuth();
  const [merchantStaff, setMerchantStaff] = useState<Staff>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!auth.user) return;

    const loadStaff = async () => {
      if (!auth.user) return;
      setLoading(true);
      const mongodb = auth.user.mongoClient("mongodb-atlas");
      const staffCollection = mongodb.db("jymba").collection<Staff>("Staff");
      const dbStaff = await staffCollection.findOne({ merchantId: merchant._id });
      if (dbStaff) {
        setMerchantStaff(dbStaff);
      }
      setLoading(false);
    }
    loadStaff();
  }, [auth.user, merchant]);

  const _handleStaffSelect = async (staff: Staff | null) => {
    if (staff) {
      setMerchantStaff(staff);
    }
  }

  const removeStaff = async (staff: Staff) => {
    if (staff && auth.user) {
      setLoading(true);

      try {

        const mongodb = auth.user.mongoClient("mongodb-atlas");
        const staffCollection = mongodb.db("jymba").collection<Staff>("Staff");

        await staffCollection.updateOne({ _id: staff._id }, { $set: { "merchantId": new BSON.ObjectID("000000000000000000000000") } });

        setMerchantStaff(undefined);
        setOpenSnackbar({ success: true, text: "Merchants Stuff updated" });
      } catch (e) {
        setOpenSnackbar({ success: false, text: e.message });
      }
      setLoading(false);
    }
  }

  const updateStaffs = async () => {
    if (auth.user && merchantStaff) {
      try {
        setLoading(true);

        const mongodb = auth.user.mongoClient("mongodb-atlas");
        const staffCollection = mongodb.db("jymba").collection<Staff>("Staff");
        const merchantCollection = mongodb.db("jymba").collection<Merchant>("Merchant");

        await staffCollection.updateOne({ _id: merchantStaff._id }, { $set: { "merchantId": merchant._id, "name": merchantStaff.name, "canReadPartitions": [`merchant=${merchant._id}`, `staff=${merchantStaff._id}`] } });

        await merchantCollection.updateOne({ _id: merchant._id }, {
          $set: {
            "_createdBy": merchantStaff._id, "_updatedBy": merchantStaff._id
          }
        });


        setLoading(false);
        setOpenSnackbar({ success: true, text: "Merchants Stuff updated" });
      } catch (e) {
        setOpenSnackbar({ success: false, text: e.message });
      }
    }
  };

  const handleNameChange = async (staff: Staff, name: string) => {
    if (merchantStaff) {
      const updatedMerchant = { ...merchantStaff };
      updatedMerchant.name = name;
      setMerchantStaff(updatedMerchant);
    }
  }

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        Add Staff to merchant
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <StaffsAutocompleteField onSelect={_handleStaffSelect} />
          <Box my={2} />
          <Divider />
        </Grid>

        {merchantStaff && (

          <Grid item container xs={12} direction="row">

            <Grid key={merchantStaff._id} item xs={4}>
              <Box m={2}>
                <Card variant="outlined">
                  <CardHeader
                    title={merchantStaff.email}
                    subheader={merchantStaff._id}
                    action={
                      <IconButton onClick={() => removeStaff(merchantStaff)}>
                        <DeleteForeverIcon />
                      </IconButton>
                    }
                  />
                  <CardContent>
                    <TextField
                      variant="outlined"
                      label="Name"
                      size="small"
                      value={merchantStaff.name}
                      onChange={(e) => handleNameChange(merchantStaff, e.target.value)}
                    />
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>

        )}
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <div className={classes.buttons}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => updateStaffs()}
          >
            Update Merchant Staffs
          </Button>
        </div>

      </Grid>

      <Snackbar open={openSnackbar !== undefined} autoHideDuration={10000} onClose={() => setOpenSnackbar(undefined)}>
        <Alert onClose={() => setOpenSnackbar(undefined)} severity={openSnackbar?.success ? 'success' : 'error'}>
          {openSnackbar?.text}
        </Alert>
      </Snackbar>

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}



const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default RealmUserForm;